import InputMaskComponent from "react-input-mask";
import { Form } from "react-bootstrap";
import withUIAwareness from "../../UIAwareInput/UIAwareInput";

const InputMask = ({
  specs,
  value,
  onChange,
  mask,
  placeholder,
  showRedBorder,
}) => {
  const { label, namespace } = specs;
  return (
    <Form.Group className="mb-3">
      <Form.Label className="simulation-form-label m-0 mb-2">
        {label}
      </Form.Label>
      <InputMaskComponent
        mask={mask}
        className={"form-control" + (showRedBorder ? " error-border" : "")}
        name={namespace}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    </Form.Group>
  );
};

export default withUIAwareness(InputMask);
