const initialState = {
  id: null,
  properties: [],
};

const policyHolderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "POLICY_HOLDER/SET_ID":
      return { ...state, id: action.payload };

    case "POLICY_HOLDER/UPDATE":
      const updatedProperties = state.properties.map((existingProperty) => {
        const matchingProperty = action.payload.properties.find(
          (updatedProperty) =>
            updatedProperty.namespace === existingProperty.namespace
        );
        if (matchingProperty !== null && matchingProperty !== undefined) {
          return { ...existingProperty, ...matchingProperty };
        }
        return existingProperty;
      });

      const newProperties = action.payload.properties.filter(
        (property) =>
          !state.properties.find(
            (existingProperty) =>
              existingProperty.namespace === property.namespace
          )
      );

      return {
        ...state,
        properties: [...updatedProperties, ...newProperties],
      };

    case "POLICY_HOLDER/CLEAR":
      return initialState;

    default:
      return state;
  }
};

export default policyHolderReducer;
