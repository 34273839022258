import {
  protectedAssetActions,
  policyHolderActions,
  userActions,
  quoteActions,
  UIactions,
} from "../../actions";
import { SECONDARY_COLOR } from "../../config";
import { Col, Form, Button, Row, Nav } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { useEffect, useState } from "react";
import { Fragment } from "react";
import RightSidebar from "./RightSidebar";
import FormControl from "./shared/FormControl";
import DatePicker from "./shared/DatePicker";
import FormCheck from "./shared/FormCheck";
import PhoneInput from "./shared/PhoneInput";
import FormSelect from "./shared/FormSelect";
import {
  isNullOrUndefined,
  getDataByNamespace,
  getSpecsByNamespace,
  getSpecsForCheckBox,
} from "./utils";
import SubmitButton from "./shared/SubmitButton";
import {
  clearLocalStorage,
  formatApiToDate,
  formatDateToApi,
  getItemLocalStorage,
  getUserData,
  treatPropertiesArrayFromResponse,
} from "../../utils/helper";
import { LOCAL_STORAGE_KEYS as LOCAL_STORAGE } from "../../utils/constants";
import {
  policyHolders,
  updateProtectedAssetProperties,
  updateQuoteProperties,
} from "../../api/requests";
import baseErrorHandler from "../../services/baseErrorHandler";

const ValidateData = (props) => {
  const policyHolderProperties = useSelector(
    (state) => state.policyHolder.properties
  );
  const protectedAssetProperties = useSelector(
    (state) => state.protectedAsset.properties
  );
  const hasProtectedAssetForm = protectedAssetProperties.some(
    (property) => property.namespace === "invoice"
  );
  const protectedAssetId = useSelector((state) => state.protectedAsset.id);
  const quoteProperties = useSelector((state) => state.quote.properties);
  const quoteID = useSelector((state) => state.quote.id);
  const insuranceProduct = useSelector((state) => state.insuranceProduct);
  const customData = useSelector((state) => state.customData);
  const distributionDetails = useSelector((state) => state.distributionDetails);
  const policyHolderID = useSelector((state) => state.policyHolder.id);
  const { id: userID } = useSelector((state) => state.user);

  const UI = useSelector((state) => state.UI);
  const loaderActions = UI?.loader?.actions ?? [];
  const isLoader = loaderActions.length > 0;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const style = { color: "#C8CFD6" };
  const [nif, setNif] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [invoice, setInvoice] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [work_regime, setWorkregime] = useState("");
  const [is_working, setIsworking] = useState(false);
  const [invoice_value, setInvoicevalue] = useState("");
  const [error, setError] = useState({ namespace: "", message: "" });
  const [current_company, setCurrentcompany] = useState("");
  const [globalDisclaimer, setGlobalDisclaimer] = useState([]);
  const [productDisclaimer, setProductDisclaimer] = useState("");
  const [date_confirmation, setDateconfirmation] = useState(false);
  const [confirmation_1, setConfirmation1] = useState(false);
  const [confirmation_2, setConfirmation2] = useState(false);

  const usertype = getItemLocalStorage(LOCAL_STORAGE.TYPE);
  const fingerprint = getItemLocalStorage(LOCAL_STORAGE.FINGERPRINT);
  const access_token = getItemLocalStorage(LOCAL_STORAGE.ACCESS_TOKEN);

  const distributorsellerID = distributionDetails?.seller?.id;
  const distributionpointID = distributionDetails?.distributionpoint?.id;

  const isSubmitButtonDisabled = () => {
    const checkMandatoryEmptyFields = () => {
      const checkEmptyField = (fieldValue) =>
        fieldValue === "" ||
        fieldValue === null ||
        fieldValue === undefined ||
        fieldValue === false;
      const mandatoryMainFormFields = [
        nif,
        birthdate,
        work_regime,
        current_company,
        is_working,
      ];
      const mandatoryInvoiceFormFields = [
        invoice,
        invoice_value,
        date_confirmation,
      ];
      const leadSpecificFormFields = [confirmation_1, confirmation_2, name];

      leadSpecificFormFields.push(checkEmptyField(email) ? mobile : email);

      const mandatoryFields = [...mandatoryMainFormFields];
      if (hasProtectedAssetForm)
        mandatoryFields.push(...mandatoryInvoiceFormFields);
      if (usertype === "lead") mandatoryFields.push(...leadSpecificFormFields);

      return mandatoryFields.some((field) => checkEmptyField(field));
    };

    const isDisabled = checkMandatoryEmptyFields();
    return isDisabled;
  };

  const paymentFrequencyLabel = getSpecsByNamespace(
    "payment_frequency",
    quoteProperties
  )?.options?.find(
    (option) =>
      option.data === getDataByNamespace("payment_frequency", quoteProperties)
  )?.label;

  const sidebarProps = {
    usertype: usertype,
    payment_frequency_name: paymentFrequencyLabel,
    rate_base: getDataByNamespace("rate_base", quoteProperties),
    productname: insuranceProduct?.name,
    documents: insuranceProduct?.documents?.at_offer,
    policyHolderName: customData?.policyholder_name,
  };

  const updateProtectedAssetAsync = async () => {
    dispatch(UIactions.startAction("PROTECTED_ASSET_UPDATE_PROPERTIES", {}));
    const updatedProperties = [
      {
        namespace: "invoice",
        data: invoice,
      },
      {
        namespace: "invoice_value",
        data: invoice_value,
      },
      {
        namespace: "date_confirmation",
        data: date_confirmation ? "true" : "false",
      },
    ];
    const response = await updateProtectedAssetProperties(
      fingerprint,
      usertype,
      access_token,
      protectedAssetId,
      updatedProperties
    );
    if (response.type === "error") {
      dispatch(UIactions.stopAction("PROTECTED_ASSET_UPDATE_PROPERTIES", {}));
      const { form, dialog } = baseErrorHandler(response);
      if (dialog?.open ?? false) dispatch(UIactions.openErrorDialog(dialog));
      if (Object.keys(form).length > 0) {
        dispatch(UIactions.addErrorMessageToNamespace(form));
      }
      return false;
    }

    dispatch(
      protectedAssetActions.update(
        treatPropertiesArrayFromResponse(response.data?.properties ?? [])
      )
    );

    dispatch(UIactions.stopAction("PROTECTED_ASSET_UPDATE_PROPERTIES", {}));
    return true;
  };

  const updatePolicyHolderInfoAsync = async () => {
    dispatch(UIactions.startAction("POLICY_HOLDERS", {}));
    const updatedProperties = [
      {
        namespace: "current_company",
        data: current_company,
      },
      {
        namespace: "is_working",
        data: is_working ? "true" : "false",
      },
      {
        namespace: "birthdate",
        data: formatDateToApi(birthdate),
      },
      {
        namespace: "work_regime",
        data: work_regime,
      },
    ];
    if (usertype !== "user")
      updatedProperties.push({
        namespace: "nif",
        data: nif,
      });

    const userData = getUserData(userID ?? "", email ?? "", name ?? "");

    const response = await policyHolders(
      fingerprint,
      usertype,
      access_token,
      quoteID,
      updatedProperties,
      userData,
      policyHolderID ?? ""
    );
    if (response.type === "error") {
      dispatch(UIactions.stopAction("POLICY_HOLDERS", {}));
      const { form, dialog } = baseErrorHandler(response);
      if (dialog?.open ?? false) dispatch(UIactions.openErrorDialog(dialog));
      if (Object.keys(form).length > 0) {
        dispatch(UIactions.addErrorMessageToNamespace(form));
      }
      return false;
    }

    dispatch(policyHolderActions.setID(response.data.id));
    dispatch(
      userActions.update({
        id: response.data?.user_id ?? userData?.id ?? "",
      })
    );
    dispatch(
      quoteActions.update(
        treatPropertiesArrayFromResponse([
          ...(response.simulated?.quote_properties ?? []),
          ...(response.customized?.quote_properties ?? []),
        ])
      )
    );

    dispatch(UIactions.stopAction("POLICY_HOLDERS", {}));
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(UIactions.clearErrorForm());

    if (hasProtectedAssetForm) await updateProtectedAssetAsync();

    const policyHolderResult = await updatePolicyHolderInfoAsync();
    if (!policyHolderResult) return false;

    updateFormDataOnRedux();
    if (usertype === "lead") await setQuoteToGenerateLeadAsync();
    dispatch(UIactions.nextStep());
    return true;
  };

  const setQuoteToGenerateLeadAsync = async () => {
    dispatch(UIactions.startAction("QUOTE_UPDATE_PROPERTIES", {}));
    const distribution_details = {
      flow: usertype,
      distribution_point: {
        id: distributionpointID,
      },
      seller: {
        id: distributorsellerID,
      },
      user_contact: {
        mobile: mobile,
        email: email,
      },
    };

    const updatedProperties = [
      {
        namespace: "distribution_details",
        data: JSON.stringify(distribution_details),
      },
    ];

    const response = await updateQuoteProperties(
      fingerprint,
      usertype,
      access_token,
      quoteID,
      updatedProperties
    );
    if (response.type === "error") {
      dispatch(UIactions.stopAction("QUOTE_UPDATE_PROPERTIES", {}));
      const { form, dialog } = baseErrorHandler(response);
      if (dialog?.open ?? false) dispatch(UIactions.openErrorDialog(dialog));
      if (Object.keys(form).length > 0) {
        dispatch(UIactions.addErrorMessageToNamespace(form));
      }
      return false;
    }

    dispatch(UIactions.stopAction("QUOTE_UPDATE_PROPERTIES", {}));
    navigate("/success");
  };

  const updateFormDataOnRedux = () => {
    const policyHolderData = [
      { namespace: "nif", data: nif },
      { namespace: "birthdate", data: formatDateToApi(birthdate) },
      { namespace: "work_regime", data: work_regime },
      { namespace: "current_company", data: current_company },
      { namespace: "is_working", data: is_working },
    ];

    const protectedAssetData = hasProtectedAssetForm
      ? [
          { namespace: "invoice", data: invoice },
          { namespace: "invoice_value", data: invoice_value },
          { namespace: "date_confirmation", data: date_confirmation },
        ]
      : [];

    dispatch(policyHolderActions.update({ properties: policyHolderData }));
    dispatch(
      protectedAssetActions.update({
        properties: protectedAssetData,
      })
    );
  };

  useEffect(() => {
    setTimeout(() => {}, 1000);

    if (isNullOrUndefined(policyHolderProperties)) return;
    if (isNullOrUndefined(protectedAssetProperties)) return;
    if (isNullOrUndefined(insuranceProduct)) return;

    const initialNif = getDataByNamespace("nif", policyHolderProperties);
    const initialBirthdate = getDataByNamespace(
      "birthdate",
      policyHolderProperties
    );
    const initialWorkregime = getDataByNamespace(
      "work_regime",
      policyHolderProperties
    );
    const initialCurrentCompany = getDataByNamespace(
      "current_company",
      policyHolderProperties
    );
    const initialIsWorking = getDataByNamespace(
      "is_working",
      policyHolderProperties
    );

    const initialInvoice = getDataByNamespace(
      "invoice",
      protectedAssetProperties
    );
    const initialInvoiceValue = getDataByNamespace(
      "invoice_value",
      protectedAssetProperties
    );
    const initialDateConfirmation = getDataByNamespace(
      "date_confirmation",
      protectedAssetProperties
    );

    setNif(initialNif);
    setBirthdate(formatApiToDate(initialBirthdate));
    setWorkregime(initialWorkregime);
    setCurrentcompany(initialCurrentCompany);
    setIsworking(initialIsWorking);

    setInvoice(initialInvoice);
    setInvoicevalue(initialInvoiceValue);
    setDateconfirmation(initialDateConfirmation);

    setGlobalDisclaimer(
      insuranceProduct.legal_disclaimers.find((x) => x.type === "global")
    );
    setProductDisclaimer(
      insuranceProduct.legal_disclaimers.find((x) => x.type === "by_product")
    );
  }, []);

  return (
    <Fragment>
      <ToastContainer theme="dark" autoClose={10000} />
      {isLoader === true && (
        <ThreeDots
          height="90"
          width="90"
          radius="9"
          color="#FDBE14"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass="ajax-loader"
          visible={true}
        />
      )}
      <Row className="text-start m-0 simulation-divalign-items-start">
        <Col md={9} className="leftbar">
          {window.innerWidth >= 768 && (
            <div className="left-content-box active">
              <h3
                className="left-content-heading m-0"
                style={{ color: SECONDARY_COLOR }}
              >
                <i className="bi bi-check-circle-fill"></i> Simulação
              </h3>
              <a
                type="button"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  updateFormDataOnRedux();
                  dispatch(UIactions.previousStep());
                }}
                className="edit_btn"
              >
                <i className="bi bi-pencil"></i>Editar
              </a>
            </div>
          )}
          {window.innerWidth < 768 && (
            <>
              <h3
                className="left-content-heading text-center d-block mt-2 mb-4"
                style={{ color: SECONDARY_COLOR }}
              >
                Dados de {usertype === "lead" ? "Validação" : "Gerais"}
              </h3>
              <Nav
                as="ul"
                className="mobile-bar step-two align-items-center justify-content-between"
              >
                <Nav.Item as="li" className="active"></Nav.Item>
                <Nav.Item as="li" className="active"></Nav.Item>
                <Nav.Item as="li"></Nav.Item>
                <Nav.Item as="li"></Nav.Item>
              </Nav>
            </>
          )}
          <div className="left-content-box">
            {window.innerWidth >= 768 && (
              <h3
                className="left-content-heading"
                style={{ color: SECONDARY_COLOR }}
              >
                <span
                  className="counter-box d-inline-flex align-items-center justify-content-center"
                  style={{
                    borderColor: SECONDARY_COLOR,
                    color: SECONDARY_COLOR,
                  }}
                >
                  2
                </span>{" "}
                Dados de {usertype === "lead" ? "Validação" : "Gerais"}
              </h3>
            )}
            <Form
              method="post"
              className="simulation-form"
              onSubmit={handleSubmit}
            >
              {/* lead & invoice */}
              {(usertype === "lead" ||
                usertype === "seller" ||
                usertype === "guest" ||
                usertype === "user") && (
                <Fragment>
                  <div className="mb-3">
                    <label className="simulate-label">
                      Validação da Situação Laboral
                    </label>
                  </div>
                  <Row>
                    {usertype !== "user" && (
                      <Col md={6}>
                        <FormControl
                          specs={getSpecsByNamespace(
                            "nif",
                            policyHolderProperties
                          )}
                          type="text"
                          placeholder="xxxxxxxx"
                          value={nif}
                          error={error}
                          onChange={(e) => setNif(e.target.value)}
                        />
                      </Col>
                    )}
                    <Col
                      md={6}
                      style={usertype === "user" ? { order: "3" } : {}}
                    >
                      <DatePicker
                        specs={getSpecsByNamespace(
                          "birthdate",
                          policyHolderProperties
                        )}
                        value={birthdate}
                        dateSetterFunction={setBirthdate}
                      />
                    </Col>
                    <Col
                      md={6}
                      style={usertype === "user" ? { order: "2" } : {}}
                    >
                      <FormSelect
                        specs={getSpecsByNamespace(
                          "work_regime",
                          policyHolderProperties
                        )}
                        value={work_regime}
                        onChange={(e) => {
                          setWorkregime(e.target.value);
                        }}
                      />
                    </Col>
                    <Col
                      md={6}
                      style={usertype === "user" ? { order: "1" } : {}}
                    >
                      <FormControl
                        specs={getSpecsByNamespace(
                          "current_company",
                          policyHolderProperties
                        )}
                        type="text"
                        placeholder="Inserir..."
                        value={current_company}
                        onChange={(e) => setCurrentcompany(e.target.value)}
                      />
                    </Col>
                    <Col
                      xs={12}
                      style={usertype === "user" ? { order: "4" } : {}}
                    >
                      <FormCheck
                        specs={getSpecsForCheckBox(
                          "is_working",
                          policyHolderProperties,
                          usertype
                        )}
                        value={is_working}
                        onChange={() => setIsworking(!is_working)}
                      />
                    </Col>
                  </Row>
                </Fragment>
              )}
              {hasProtectedAssetForm && (
                <Fragment>
                  <Row className="mt-4">
                    <div className="mb-3">
                      <label className="simulate-label">
                        Validação da Fatura
                      </label>
                    </div>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                      {/* Still missing to Test this */}
                      <FormControl
                        specs={getSpecsByNamespace(
                          "invoice",
                          protectedAssetProperties
                        )}
                        type="text"
                        placeholder="Inserir..."
                        value={invoice}
                        onChange={(e) => setInvoice(e.target.value)}
                      />
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                      <FormControl
                        specs={getSpecsByNamespace(
                          "invoice_value",
                          protectedAssetProperties
                        )}
                        type="text"
                        placeholder="Ex: 1450,50€"
                        value={invoice_value}
                        onChange={(e) => setInvoicevalue(e.target.value)}
                      />
                    </Col>
                    <div className="mb-3">
                      <label className="simulate-label">
                        Atenção, só poderá subscrever o Seguro de Proteção de
                        Pagamentos no momento da compra
                      </label>
                    </div>
                    <FormCheck
                      specs={getSpecsForCheckBox(
                        "date_confirmation",
                        protectedAssetProperties,
                        usertype
                      )}
                      value={date_confirmation}
                      onChange={() => setDateconfirmation(!date_confirmation)}
                    />
                  </Row>
                </Fragment>
              )}

              {usertype === "lead" && (
                <Fragment>
                  <Row className="">
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                      <FormCheck
                        specs={getSpecsForCheckBox(
                          "confirmation_1",
                          "",
                          usertype
                        )}
                        value={confirmation_1}
                        onChange={() => setConfirmation1(!confirmation_1)}
                      />
                      <FormCheck
                        specs={getSpecsForCheckBox(
                          "confirmation_2",
                          "",
                          usertype
                        )}
                        value={confirmation_2}
                        onChange={() => setConfirmation2(!confirmation_2)}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <div className="mb-3">
                      <label className="simulate-secondary-label">
                        Como pretende o cliente receber a simulação do seguro?
                      </label>
                    </div>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                      <FormControl
                        specs={getSpecsByNamespace(
                          "email",
                          policyHolderProperties
                        )}
                        type="text"
                        placeholder="Inserir..."
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                      <PhoneInput
                        specs={getSpecsByNamespace(
                          "mobile",
                          policyHolderProperties
                        )}
                        value={mobile}
                        onChange={setMobile}
                      />
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                      <FormControl
                        specs={getSpecsByNamespace(
                          "name",
                          policyHolderProperties
                        )}
                        type="text"
                        placeholder="Inserir..."
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Col>
                  </Row>
                </Fragment>
              )}
              <SubmitButton isDisabled={isSubmitButtonDisabled()} />
            </Form>
          </div>
          {usertype !== "lead" && (
            <>
              <div className="left-content-box inactive">
                <h3 className="left-content-heading m-0" style={style}>
                  <span className="counter-box d-inline-flex align-items-center justify-content-center">
                    3
                  </span>{" "}
                  Dados do Beneficiário
                </h3>
              </div>
              <div className="left-content-box inactive">
                <h3 className="left-content-heading m-0" style={style}>
                  <span className="counter-box d-inline-flex align-items-center justify-content-center">
                    4
                  </span>{" "}
                  Pagamento
                </h3>
              </div>
            </>
          )}
          <div className="d-block footer-div">
            <div className="footer-text">
              {globalDisclaimer ? (
                <p className="m-0">{globalDisclaimer.text}</p>
              ) : (
                ""
              )}
              {productDisclaimer ? (
                <p className="m-0">{productDisclaimer.text}</p>
              ) : (
                ""
              )}
            </div>
          </div>
        </Col>
        <Col md={3} className="right-sidebar">
          <RightSidebar
            props={sidebarProps}
            previousFunction={() => dispatch(UIactions.previousStep())}
            submitFunction={handleSubmit}
            canSubmit={!isSubmitButtonDisabled()}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default ValidateData;
