const initialState ={
  rate_base_table: [],
  policyholder_name: '',
  invoice_type: [],
  payment_frequency: [],
  total_amount: [],
};

const customDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CUSTOM_DATA/UPDATE':
      return { ...state, ...action.payload };

    case 'CUSTOM_DATA/CLEAR':
      return initialState;

    default:
      return state;
  }
};

export default customDataReducer;