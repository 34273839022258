import { LOCAL_STORAGE_KEYS as LOCAL_STORAGE } from "../utils/constants";
import {
  setItemLocalStorage,
  clearLocalStorage,
  setDistributionDetails,
  treatPropertiesArrayFromResponse,
} from "../utils/helper";
import { ThreeDots } from "react-loader-spinner";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  customDataActions,
  quoteActions,
  protectedAssetActions,
  policyHolderActions,
  userActions,
  distributionDetailsActions,
  insuranceProductActions,
  UIactions,
} from "../actions/index";
import {
  authUsers,
  getQuoteSpecs,
  getQuotes,
  retrieveRateBaseTable,
} from "../api/requests";
import baseErrorHandler from "../services/baseErrorHandler";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const UI = useSelector((state) => state.UI);
  const loaderActions = UI?.loader?.actions ?? [];
  const isLoader = loaderActions.length > 0;

  useEffect(() => {
    dispatch(customDataActions.clear({}));
    dispatch(distributionDetailsActions.clear({}));
    dispatch(insuranceProductActions.clear({}));
    dispatch(policyHolderActions.clear({}));
    dispatch(protectedAssetActions.clear({}));
    dispatch(quoteActions.clear({}));
    dispatch(userActions.clear({}));
    dispatch(UIactions.setUiInitialState({}));

    clearLocalStorage();
    const redirectURI = window.location.href;
    setItemLocalStorage(LOCAL_STORAGE.REDIRECT_URI, redirectURI);

    const queryParameters = new URLSearchParams(window.location.search);
    const type = queryParameters.get("type");
    const fingerprint = queryParameters.get("fingerprint");
    const access_token = queryParameters.get("access_token");

    if (type === "lead" || type === "seller") {
      const distributorseller_id = queryParameters.get("distributorseller_id");
      const distributionpoint_id = queryParameters.get("distributionpoint_id");
      const distributionDetails = setDistributionDetails(
        type,
        distributionpoint_id,
        distributorseller_id,
        redirectURI
      );
      setItemLocalStorage(LOCAL_STORAGE.TYPE, type);
      setItemLocalStorage(LOCAL_STORAGE.FINGERPRINT, fingerprint);
      setItemLocalStorage(LOCAL_STORAGE.ACCESS_TOKEN, access_token);
      setItemLocalStorage(
        LOCAL_STORAGE.DISTRIBUTION_DETAILS,
        JSON.stringify(distributionDetails)
      );

      dispatch(distributionDetailsActions.update(distributionDetails));
      navigate("/bio", {
        state: {
          component: "Login",
        },
      });
      return;
    }

    if (type === "user") {
      const quote_id = queryParameters.get("quote_id");
      const fingerprint = queryParameters.get("fingerprint");

      setItemLocalStorage(LOCAL_STORAGE.TYPE, type);
      dispatch(quoteActions.setID(quote_id));

      authUsersAsync(fingerprint, quote_id, type, access_token);
      return;
    }

    clearLocalStorage();
    setItemLocalStorage(LOCAL_STORAGE.TYPE, "guest");
    navigate("/bio", {
      state: {
        component: "Login",
      },
    });
  }, [navigate]);

  const getQuoteSpecsAsync = async (fingerprint, type, access_token) => {
    dispatch(UIactions.startAction("GET_QUOTE_SPECS", {}));
    const response = await getQuoteSpecs(fingerprint, type, access_token);

    if (response.type === "error") {
      dispatch(UIactions.stopAction("GET_QUOTE_SPECS", {}));
      const { form, dialog } = baseErrorHandler(response);
      if (dialog?.open ?? false) dispatch(UIactions.openErrorDialog(dialog));
      if (Object.keys(form).length > 0) {
        dispatch(UIactions.addErrorMessageToNamespace(form));
      }

      return;
    }

    const quotePropertiesUpdate = treatPropertiesArrayFromResponse(
      response?.data?.quote_specs?.specs?.quoteproperty_specs ?? []
    );
    const policyHolderPropertiesUpdate = treatPropertiesArrayFromResponse(
      response?.data?.quote_specs?.specs?.policyholderproperty_specs ?? []
    );
    const protectedAssetPropertiesUpdate = treatPropertiesArrayFromResponse(
      response?.data?.quote_specs?.specs?.protectedassetproperty_specs ?? []
    );
    const insuranceProductUpdate = response?.data?.insurance_product;

    dispatch(quoteActions.update(quotePropertiesUpdate));
    dispatch(policyHolderActions.update(policyHolderPropertiesUpdate));
    dispatch(protectedAssetActions.update(protectedAssetPropertiesUpdate));
    dispatch(insuranceProductActions.update(insuranceProductUpdate));
    dispatch(UIactions.stopAction("GET_QUOTE_SPECS", {}));
  };

  const getQuotesAsync = async (
    fingerprint,
    quoteID,
    type,
    access_token,
    persistantPolicyHolderProperties
  ) => {
    dispatch(UIactions.startAction("GET_QUOTES", {}));
    const response = await getQuotes(fingerprint, quoteID);

    if (response.type === "error") {
      dispatch(UIactions.stopAction("GET_QUOTES", {}));
      const { form, dialog } = baseErrorHandler(response);
      if (dialog?.open ?? false) dispatch(UIactions.openErrorDialog(dialog));
      if (Object.keys(form).length > 0) {
        dispatch(UIactions.addErrorMessageToNamespace(form));
      }
      return;
    }

    const {
      policy_holders: policyHolders,
      protected_assets: protectedAssets,
      quote,
    } = response.data;
    const { properties: policyHolderProperties, id: policyHolderID } =
      policyHolders?.[0] ?? {};
    const { properties: protectedAssetProperties, id: protectedAssetID } =
      protectedAssets?.[0] ?? {};
    const {
      properties: quoteProperties,
      user_id: userID,
      state: quoteState,
    } = quote ?? {};

    if (quoteState !== "simulated") {
      dispatch(UIactions.stopAction("GET_QUOTES", {}));
      navigate("/unavailable");
      throw new Error("unavailable");
    }

    const distributionDetails =
      quoteProperties?.find(
        (match) => match.namespace === "distribution_details"
      )?.data ?? "";
    const { email: userEmail, mobile: userMobile } =
      JSON.parse(distributionDetails)?.user_contact ?? {};

    if (persistantPolicyHolderProperties !== undefined)
      persistantPolicyHolderProperties.push(
        { namespace: "email", data: userEmail },
        { namespace: "mobile", data: userMobile }
      );

    const policyHolderUpdate = treatPropertiesArrayFromResponse(
      policyHolderProperties ?? []
    );
    const persistantPolicyHolderData = treatPropertiesArrayFromResponse(
      persistantPolicyHolderProperties ?? []
    );
    const protectedAssetUpdate = treatPropertiesArrayFromResponse(
      protectedAssetProperties ?? []
    );
    const quoteUpdate = treatPropertiesArrayFromResponse(quoteProperties ?? []);

    dispatch(policyHolderActions.update(policyHolderUpdate));
    dispatch(policyHolderActions.update(persistantPolicyHolderData));

    dispatch(protectedAssetActions.update(protectedAssetUpdate));
    dispatch(quoteActions.update(quoteUpdate));

    dispatch(policyHolderActions.setID(policyHolderID ?? ""));
    dispatch(protectedAssetActions.setID(protectedAssetID ?? ""));
    dispatch(userActions.update({ id: userID ?? "" }));
    dispatch(UIactions.stopAction("GET_QUOTES", {}));
  };

  const getRateBaseTableAsync = async (fingerprint, type, access_token) => {
    dispatch(UIactions.startAction("RETRIEVE_RATE_BASE_TABLE", {}));
    const response = await retrieveRateBaseTable(
      fingerprint,
      type,
      access_token
    );
    if (response.type === "error") {
      dispatch(UIactions.stopAction("RETRIEVE_RATE_BASE_TABLE", {}));
      const { form, dialog } = baseErrorHandler(response);
      if (dialog?.open ?? false) dispatch(UIactions.openErrorDialog(dialog));
      if (Object.keys(form).length > 0) {
        dispatch(UIactions.addErrorMessageToNamespace(form));
      }
      return;
    }

    const customData = response?.data?.custom_data;
    dispatch(customDataActions.update(customData));

    dispatch(UIactions.stopAction("RETRIEVE_RATE_BASE_TABLE", {}));
  };

  const authUsersAsync = async (fingerprint, quoteID, type, access_token) => {
    dispatch(UIactions.startAction("AUTH_USERS", {}));
    const authResponse = await authUsers(fingerprint);
    if (authResponse.type === "error") {
      dispatch(UIactions.stopAction("AUTH_USERS", {}));
      const { form, dialog } = baseErrorHandler(authResponse);
      if (dialog?.open ?? false) dispatch(UIactions.openErrorDialog(dialog));
      if (Object.keys(form).length > 0) {
        dispatch(UIactions.addErrorMessageToNamespace(form));
      }
      return;
    }

    const {
      fingerprint: xFingerprint,
      email,
      name,
      mobile,
    } = authResponse?.data ?? {};
    dispatch(userActions.update({ email, name, mobile }));
    const persistantPolicyHolderProperties = [
      { namespace: "name", data: name },
    ];
    setItemLocalStorage(LOCAL_STORAGE.FINGERPRINT, xFingerprint);
    try {
      await getQuoteSpecsAsync(xFingerprint, type, access_token);
      await getQuotesAsync(
        xFingerprint,
        quoteID,
        type,
        access_token,
        persistantPolicyHolderProperties
      );
      await getRateBaseTableAsync(xFingerprint, type, access_token);

      dispatch(UIactions.stopAction("AUTH_USERS", {}));
      navigate("/simulation");
    } catch {}
  };

  return (
    <>
      <ToastContainer theme="dark" autoClose={10000} />
      {isLoader === true && (
        <ThreeDots
          height="90"
          width="90"
          radius="9"
          color="#FDBE14"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass="ajax-loader"
          visible={true}
        />
      )}
    </>
  );
};

export default Login;
