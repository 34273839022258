import { Form } from "react-bootstrap";
import withUIAwareness from "../../UIAwareInput/UIAwareInput";

const FormControl = ({
  type,
  specs,
  placeholder,
  value,
  onChange,
  showRedBorder,
}) => {
  const { label, namespace } = specs;
  const inputClassName = showRedBorder ? "error-border" : "";

  return (
    <Form.Group className="mb-3">
      <Form.Label className="simulation-form-label m-0 mb-2">
        {label}
      </Form.Label>
      <Form.Control
        type={type}
        className={inputClassName}
        name={namespace}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </Form.Group>
  );
};

export default withUIAwareness(FormControl);
