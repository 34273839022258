import { Button } from "react-bootstrap";
import "../../../assets/css/style.css";

const SubmitButton = ({ isDisabled }) => {
  const buttonStyle = `simulation-button ${
    isDisabled ? "disabled" : ""
  } btn-warning`;

  return (
    <>
      <Button className={`${buttonStyle} d-none d-md-block`} type="submit">
        Continuar
      </Button>
    </>
  );
};

export default SubmitButton;
