import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import customDataReducer from "./reducers/customDataReducer";
import distributionDetailsReducer from "./reducers/distributionDetailsReducer";
import insuranceProductReducer from "./reducers/insuranceProductReducer";
import policyHolderReducer from "./reducers/policyHolderReducer";
import protectedAssetReducer from "./reducers/protectedAssetReducer";
import quoteReducer from "./reducers/quoteReducer";
import userReducer from "./reducers/userReducer";

import uiReducer from "./reducers/uiReducer";

import clearPersist from "./reducers/clearStore";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const reducer = combineReducers({
  UI: uiReducer,
  customData: customDataReducer,
  quote: quoteReducer,
  protectedAsset: protectedAssetReducer,
  policyHolder: policyHolderReducer,
  user: userReducer,
  distributionDetails: distributionDetailsReducer,
  insuranceProduct: insuranceProductReducer,

  clearPersist,
});

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({ reducer: persistedReducer });

export default store;
