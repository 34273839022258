import { Form } from "react-bootstrap";
import withUIAwareness from "../../UIAwareInput/UIAwareInput";

const FormLabel = ({ noLabel, label }) => {
  if (noLabel === true) return <></>;
  return (
    <Form.Label className="simulation-form-label m-0 mb-2">{label}</Form.Label>
  );
};

const FormRadio = ({ specs, value, onChange, noLabel, showRedBorder }) => {
  const { label, namespace, options } = specs;

  return (
    <Form.Group className="mb-3">
      <FormLabel noLabel={noLabel} label={label} />
      <div className="d-flex flex-wrap">
        {options.map(({ data, label }, index) => {
          const isLastOption = index === options.length - 1;
          return (
            <Form.Check
              type="radio"
              name={namespace}
              key={data}
              value={data}
              checked={value === data}
              label={label}
              className={`${isLastOption ? "" : "me-2"}${
                showRedBorder ? " error-border" : ""
              }`}
              onChange={onChange}
            />
          );
        })}
      </div>
    </Form.Group>
  );
};

export default withUIAwareness(FormRadio);
