import { useDispatch, useSelector } from "react-redux";
import { LOCAL_STORAGE_KEYS as LOCAL_STORAGE } from "../utils/constants";
import BeneficiaryData from "./forms/BeneficiaryData";
import ValidateData from "./forms/ValidateData";
import Simulation from "./forms/Simulation";
import { Fragment, useEffect } from "react";
import Payment from "./forms/Payment";
import { getItemLocalStorage } from "../utils/helper";
import { UIactions } from "../actions/index";
import ErrorDialog from "./ErrorDialog";

const SimulationForm = () => {
  const dispatch = useDispatch();
  const quoteProperties = useSelector((state) => state.quote.properties);
  const userType = getItemLocalStorage(LOCAL_STORAGE.TYPE);

  useEffect(() => {
    if (userType === "user") {
      dispatch(UIactions.setStep(1));
    }
    if (Object.keys(quoteProperties).length > 0) {
      dispatch(UIactions.setStep(1));
    }
  }, []);

  const step = useSelector((state) => state?.UI?.flow?.step ?? 0);
  const isErrorOpen = useSelector(
    (state) => state.UI?.error?.dialog?.open ?? false
  );
  const errorDialogCode = useSelector(
    (state) => state.UI?.error?.dialog?.code ?? "default"
  );
  const errorDialog = {
    open: isErrorOpen,
    code: errorDialogCode,
  };

  return (
    <Fragment>
      <ErrorDialog dialog={errorDialog} />
      {step === 0 ? (
        <Simulation />
      ) : step === 1 ? (
        <ValidateData />
      ) : step === 2 ? (
        <BeneficiaryData />
      ) : step === 3 ? (
        <Payment />
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default SimulationForm;
