import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { BASENAME } from "./config";
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/media.css";
import store from "./store";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

const root = ReactDOM.createRoot(document.getElementById("root"));
const persistor = persistStore(store);
const needsBasename = () => {
  const location = window.location;

  const needsBasenameArray = [
    "distributors.habit.io",
    "distributors.qa.habit.io",
    "distributors.stg.habit.io",
    "distributors.integrations.habit.io",
  ];

  return needsBasenameArray.includes(location.hostname);
};
root.render(
  <BrowserRouter basename={needsBasename() ? `/${BASENAME}` : "/"}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
