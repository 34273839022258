import {
  quoteActions,
  protectedAssetActions,
  UIactions,
} from "../../actions/index";
import { SECONDARY_COLOR } from "../../config";
import { Col, Form, Badge, Button, Nav, Row } from "react-bootstrap";
import {
  getItemLocalStorage,
  treatPropertiesArrayFromResponse,
} from "../../utils/helper";
import { ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import { Fragment } from "react";
import RightSidebar from "./RightSidebar";
import {
  getAvailableFrequencies,
  getCurrentOrLastFrequency,
  getRateBaseForAmountAndFrequency,
  getSpecsByNamespace,
} from "./utils";
import PriceSlider from "./shared/PriceSlider";
import { LOCAL_STORAGE_KEYS as LOCAL_STORAGE } from "../../utils/constants";
import FormRadio from "./shared/FormRadio";
import {
  updateProtectedAssetProperties,
  updateQuoteProperties,
} from "../../api/requests";
import baseErrorHandler from "../../services/baseErrorHandler";

const Simulation = () => {
  const customData = useSelector((state) => state.customData);
  const quoteProperties = useSelector((state) => state.quote.properties);
  const protectedAssetProperties = useSelector(
    (state) => state.protectedAsset.properties
  );
  const insuranceProduct = useSelector((state) => state.insuranceProduct);
  const quoteID = useSelector((state) => state.quote.id);
  const protectedAssetID = useSelector((state) => state.protectedAsset.id);

  const UI = useSelector((state) => state.UI);
  const loaderActions = UI?.loader?.actions ?? [];
  const isLoader = loaderActions.length > 0;

  const dispatch = useDispatch();
  const style = { color: "#C8CFD6" };
  const inputRangeRef = useRef(null);
  const [insuredAmount, setInsuredAmount] = useState("");
  const [invoice_type, setInvoicetype] = useState(
    protectedAssetProperties
      .find(
        (invoice_typeFromData) =>
          invoice_typeFromData.namespace === "invoice_type"
      )
      ?.data?.split(",") ?? []
  );
  const [globalDisclaimer, setGlobalDisclaimer] = useState([]);
  const [payment_frequency, setPaymentFrequency] = useState("");
  const [productDisclaimer, setProductDisclaimer] = useState("");
  const [paymentFrequencyLabel, setPaymentFrequencyLabel] = useState("");
  const [rateBase, setRateBase] = useState("");

  const usertype = getItemLocalStorage(LOCAL_STORAGE.TYPE);
  const fingerprint = getItemLocalStorage(LOCAL_STORAGE.FINGERPRINT);
  const accessToken = getItemLocalStorage(LOCAL_STORAGE.ACCESS_TOKEN);

  const sidebarProps = {
    usertype: usertype,
    payment_frequency_name: paymentFrequencyLabel,
    rate_base: rateBase,
    productname: insuranceProduct.name,
    documents: insuranceProduct.documents.at_offer,
    policyHolderName: customData.policyholder_name,
  };

  const availableInvoices = customData?.invoice_type ?? [];
  const canSubmit =
    availableInvoices.length > 0 ? invoice_type.length > 0 : true;

  const handleInvoice = (e) => {
    let updateInvoice = [...invoice_type];
    if (e.target.checked) {
      updateInvoice = [...invoice_type, e.target.value];
    } else {
      updateInvoice.splice(invoice_type.indexOf(e.target.value), 1);
    }
    setInvoicetype(updateInvoice);
  };

  const updateQuoteAsync = async () => {
    dispatch(UIactions.startAction("QUOTE_UPDATE_PROPERTIES", {}));
    const updatedProperties = [
      { namespace: "payment_frequency", data: payment_frequency },
    ];

    const response = await updateQuoteProperties(
      fingerprint,
      usertype,
      accessToken,
      quoteID,
      updatedProperties
    );

    if (response.type === "error") {
      const { form, dialog, platform_confirmation } =
        baseErrorHandler(response);
      if (dialog?.open ?? false) dispatch(UIactions.openErrorDialog(dialog));
      if (Object.keys(form).length > 0) {
        dispatch(UIactions.addErrorMessageToNamespace(form));
      }
      const { properties, n_updated } = platform_confirmation ?? {};
      const quoteUpdates = treatPropertiesArrayFromResponse(properties ?? []);

      if (n_updated > 0) dispatch(quoteActions.update(quoteUpdates));
      dispatch(UIactions.stopAction("QUOTE_UPDATE_PROPERTIES", {}));
      return false;
    }

    const quoteUpdates = treatPropertiesArrayFromResponse([
      ...(response?.data?.properties ?? []),
      ...(response?.data?.simulated?.quote_properties ?? []),
      ...(response?.data?.customized?.quote_properties ?? []),
    ]);

    dispatch(quoteActions.update(quoteUpdates));
    dispatch(UIactions.stopAction("QUOTE_UPDATE_PROPERTIES", {}));
    return true;
  };

  const updateProtectedAssetAsync = async () => {
    dispatch(UIactions.startAction("PROTECTED_ASSET_UPDATE_PROPERTIES", {}));
    const updatedProperties = [
      { namespace: "total_amount", data: insuredAmount },
    ];
    if (invoice_type.length > 0)
      updatedProperties.push({
        namespace: "invoice_type",
        data: invoice_type.toString(),
      });

    const response = await updateProtectedAssetProperties(
      fingerprint,
      usertype,
      accessToken,
      protectedAssetID,
      updatedProperties
    );
    if (response.type === "error") {
      dispatch(UIactions.stopAction("PROTECTED_ASSET_UPDATE_PROPERTIES", {}));
      const { form, dialog } = baseErrorHandler(response);
      if (dialog?.open ?? false) dispatch(UIactions.openErrorDialog(dialog));
      if (Object.keys(form).length > 0) {
        dispatch(UIactions.addErrorMessageToNamespace(form));
      }
      return false;
    }

    const protectedAssetUpdates = treatPropertiesArrayFromResponse([
      ...(response?.data?.properties ?? []),
    ]);

    const quoteUpdates = treatPropertiesArrayFromResponse([
      ...(response?.data?.simulated?.quote_properties ?? []),
      ...(response?.data?.customized?.quote_properties ?? []),
    ]);

    dispatch(protectedAssetActions.update(protectedAssetUpdates));
    dispatch(quoteActions.update(quoteUpdates));
    dispatch(UIactions.stopAction("PROTECTED_ASSET_UPDATE_PROPERTIES", {}));

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(UIactions.clearErrorForm());

    await updateQuoteAsync();
    await updateProtectedAssetAsync();

    // const protectedAssetUpdate = {
    //   properties: [{ namespace: "total_amount", data: insuredAmount }],
    // };
    // if (invoice_type.length > 0)
    //   protectedAssetUpdate.properties[1] = {
    //     namespace: "invoice_type",
    //     data: invoice_type.toString(),
    //   };
    // dispatch(protectedAssetActions.update(protectedAssetUpdate));
    dispatch(UIactions.nextStep());
  };

  useEffect(() => {
    const initialRateBase = quoteProperties.find(
      (property) => property.namespace === "rate_base"
    ).data;
    const { data: initialPaymentFrequency, options: paymentFrequencies } =
      quoteProperties.find(
        (property) => property.namespace === "payment_frequency"
      );
    const initialPaymentFrequencyLabel = paymentFrequencies.find(
      ({ data }) => data === initialPaymentFrequency
    ).label;
    const initialInsuredAmount = protectedAssetProperties.find(
      (property) => property.namespace === "total_amount"
    ).data;

    setRateBase(initialRateBase);
    setPaymentFrequency(initialPaymentFrequency);
    setPaymentFrequencyLabel(initialPaymentFrequencyLabel);
    setInsuredAmount(initialInsuredAmount);

    setGlobalDisclaimer(
      insuranceProduct.legal_disclaimers.find((x) => x.type === "global")
    );
    setProductDisclaimer(
      insuranceProduct.legal_disclaimers.find((x) => x.type === "by_product")
    );

    setTimeout(function () {}, 1500);
  }, [inputRangeRef]);

  const handleSliderChange = (event) => {
    const newValue = event.target.value;
    const newAmount = customData.total_amount[newValue];

    const fakeEvent = {
      target: {
        value: newAmount.data,
      },
    };
    handleInsuredAmountChange(fakeEvent);
  };

  const handleInsuredAmountChange = (event) => {
    const rateBaseTable = customData.rate_base_table;

    const newInsuredAmount = event.target.value;
    const firstAvailableFrequency = getCurrentOrLastFrequency(
      customData.payment_frequency,
      rateBaseTable,
      newInsuredAmount
    );
    const newRateBase = getRateBaseForAmountAndFrequency(
      rateBaseTable,
      newInsuredAmount,
      firstAvailableFrequency.data
    );

    setPaymentFrequency(firstAvailableFrequency.data);
    setPaymentFrequencyLabel(firstAvailableFrequency.label);
    setInsuredAmount(newInsuredAmount);
    setRateBase(newRateBase);
  };

  const handlePaymentFrequencyChange = (event) => {
    const rateBaseTable = customData.rate_base_table;

    const selectedFrequency = event.target.value;
    const newRateBase = getRateBaseForAmountAndFrequency(
      rateBaseTable,
      insuredAmount,
      selectedFrequency
    );
    const { label: newLabel, data: newData } =
      customData.payment_frequency.find(
        (frequency) => frequency.data === selectedFrequency
      );

    setPaymentFrequency(newData);
    setPaymentFrequencyLabel(newLabel);
    setRateBase(newRateBase);
  };

  return (
    <Fragment>
      <ToastContainer theme="dark" autoClose={10000} />
      {isLoader === true && (
        <ThreeDots
          height="90"
          width="90"
          radius="9"
          color="#FDBE14"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass="ajax-loader"
          visible={true}
        />
      )}
      <Row className="text-start m-0 simulation-div align-items-start">
        <Col md={9} className="leftbar">
          {window.innerWidth < 768 ? (
            <Fragment>
              <h3
                className="left-content-heading text-center d-block mt-2 mb-4"
                style={{ color: SECONDARY_COLOR }}
              >
                Simulação
              </h3>
              <Nav
                as="ul"
                className="mobile-bar step-one align-items-center justify-content-between"
              >
                <Nav.Item as="li" className="active"></Nav.Item>
                <Nav.Item as="li"></Nav.Item>
                <Nav.Item as="li"></Nav.Item>
                <Nav.Item as="li"></Nav.Item>
              </Nav>
            </Fragment>
          ) : (
            ""
          )}
          <div className="left-content-box">
            {window.innerWidth >= 768 ? (
              <h3
                className="left-content-heading"
                style={{ color: SECONDARY_COLOR }}
              >
                <span
                  className="counter-box d-inline-flex align-items-center justify-content-center"
                  style={{
                    borderColor: SECONDARY_COLOR,
                    color: SECONDARY_COLOR,
                  }}
                >
                  1
                </span>{" "}
                Simulação
              </h3>
            ) : (
              ""
            )}
            <Form
              method="post"
              className="simulation-form"
              onSubmit={handleSubmit}
            >
              {availableInvoices.length > 0 && (
                <Form.Group className="mb-3">
                  <Form.Label
                    style={{ color: SECONDARY_COLOR }}
                    className="simulation-label"
                  >
                    Que faturas queres proteger?
                  </Form.Label>
                  <div className="invoice-type d-flex flex-wrap">
                    {availableInvoices.length > 0 &&
                      availableInvoices.map((val, index) => {
                        return (
                          <Badge
                            id={index}
                            key={index}
                            pill
                            bg={`${
                              invoice_type.includes(val.data) === true
                                ? "primary"
                                : "white"
                            } `}
                            className={`me-2 mb-2 ${
                              val.checked === true ? "pe-3" : ""
                            }`}
                          >
                            <Form.Check
                              type="checkbox"
                              value={val.data}
                              onChange={handleInvoice}
                              checked={
                                invoice_type.includes(val.data) ? true : ""
                              }
                            />
                            {invoice_type.includes(val.data) ? (
                              <i className="bi bi-check-lg me-2"></i>
                            ) : (
                              ""
                            )}
                            {val.label}
                          </Badge>
                        );
                      })}
                  </div>
                </Form.Group>
              )}
              <Form.Group className="mb-3">
                <Form.Label
                  style={{ color: SECONDARY_COLOR }}
                  className="simulation-label"
                >
                  Qual o montante mensal a proteger?
                </Form.Label>
                <PriceSlider
                  totalAmountList={customData.total_amount}
                  totalAmount={insuredAmount}
                  onChange={(e) => handleSliderChange(e)}
                />
                <Form.Select
                  name="amount"
                  className="d-flex d-md-none"
                  onChange={(e) => handleInsuredAmountChange(e)}
                >
                  {customData.total_amount &&
                    customData.total_amount.map((val, index) => {
                      return (
                        <option key={index} value={val.data}>
                          {val.label}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
              {customData.payment_frequency.length > 1 && (
                <Form.Group className="mb-3 d-flex align-items-center">
                  {insuredAmount && (
                    <FormRadio
                      specs={getSpecsByNamespace("payment_frequency", [
                        {
                          data: payment_frequency,
                          namespace: "payment_frequency",
                          options:
                            getAvailableFrequencies(
                              customData.payment_frequency,
                              customData.rate_base_table,
                              insuredAmount
                            ) ?? [],
                        },
                      ])}
                      value={payment_frequency}
                      onChange={(e) => handlePaymentFrequencyChange(e)}
                    />
                  )}
                </Form.Group>
              )}
              {window.innerWidth >= 768 && (
                <Form.Group>
                  <Button
                    className={`submit-button ${canSubmit ? " able" : ""}`}
                    variant="warning"
                    type={canSubmit ? "submit" : "button"}
                  >
                    Continuar
                  </Button>
                </Form.Group>
              )}
            </Form>
          </div>
          <div className="left-content-box inactive">
            <h3 className="left-content-heading m-0" style={style}>
              <span className="counter-box d-inline-flex align-items-center justify-content-center">
                2
              </span>{" "}
              Dados de {usertype === "lead" ? "Validação" : "Gerais"}
            </h3>
          </div>
          <div className="left-content-box inactive">
            <h3 className="left-content-heading m-0" style={style}>
              <span className="counter-box d-inline-flex align-items-center justify-content-center">
                3
              </span>{" "}
              Pagamento
            </h3>
          </div>
          <div className="d-block footer-div">
            <div className="footer-text">
              {globalDisclaimer ? (
                <p className="m-0">{globalDisclaimer.text}</p>
              ) : (
                ""
              )}
              {productDisclaimer ? (
                <p className="m-0">{productDisclaimer.text}</p>
              ) : (
                ""
              )}
            </div>
          </div>
        </Col>
        <Col md={3} className="right-sidebar">
          <RightSidebar
            props={sidebarProps}
            previousFunction={null}
            submitFunction={handleSubmit}
            canSubmit={canSubmit}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default Simulation;
