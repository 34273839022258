export const setID = (data) => ({
  type: "PROTECTED_ASSET/SET_ID",
  payload: data,
});

export const update = (data) => ({
  type: "PROTECTED_ASSET/UPDATE",
  payload: data,
});

export const clear = () => ({
  type: "PROTECTED_ASSET/CLEAR",
  payload: {},
});
