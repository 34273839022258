import { useRef, useState, useEffect, cloneElement } from "react";
import { useSelector } from "react-redux";
import Skeleton from "../forms/shared/Skeleton";

const ErrorList = ({ errorMessages }) => {
  return (
    <ul className="error-list">
      {errorMessages.map((message, index) => (
        <li key={index}>{message}</li>
      ))}
    </ul>
  );
};

const UIAwareInput = ({ children }) => {
  const [showRedBorder, setShowRedBorder] = useState(false);
  const inputRef = useRef(null);

  const { error, loader } = useSelector((state) => state.UI);

  const namespace = children?.props?.specs?.namespace ?? "";
  const errorMessages = error?.form?.[namespace] ?? [];
  const isRefreshing = loader?.refreshing?.includes("form") ?? false;

  useEffect(() => {
    const scrollIntoView = () => {
      const isFirstInputWithErrorOnTheForm = () => {
        const inputsWithError = Object.keys(error.form).filter(
          (key) => error.form[key].length > 0 && key !== "root"
        );
        const errorIndex = inputsWithError.findIndex(
          (key) => key === namespace
        );
        return errorIndex === 0;
      };
      if (inputRef.current && isFirstInputWithErrorOnTheForm())
        inputRef.current.scrollIntoView({ behavior: "smooth" });
    };
    const handleRedBorder = () => {
      if (errorMessages.length > 0)
        setShowRedBorder((prevState) => {
          if (prevState === false) scrollIntoView();
          return true;
        });
      else setShowRedBorder(false);
    };

    handleRedBorder();
  }, [errorMessages.length === 0, errorMessages]);

  const childElement = cloneElement(children, {
    ...children.props,
    ref: inputRef,
    showRedBorder,
  });

  return (
    (isRefreshing ? (
      <Skeleton />
    ) : (
      <>
        {childElement}
        {errorMessages.length > 0 && (
          <ErrorList errorMessages={errorMessages} />
        )}
      </>
    )) || <Skeleton />
  );
};

const withUIAwareness = (Component) => {
  return (props) => (
    <UIAwareInput>
      <Component {...props} />
    </UIAwareInput>
  );
};

export default withUIAwareness;
