import { Form } from "react-bootstrap";

const PriceSlider = ({ totalAmountList, totalAmount, onChange }) => {
  const getValueFromTotalAmount = (currentAmount) => {
    const value = totalAmountList.findIndex(
      (amount) => amount.data === currentAmount
    );
    return value;
  };

  const calculateColorChangePercentage = () => {
    const value = getValueFromTotalAmount(totalAmount);
    const percentage = (value / (totalAmountList.length - 1)) * 100;
    return percentage;
  };

  const colorChangePercentage = calculateColorChangePercentage();
  const trackerColorStyle = {
    background: `linear-gradient(to right, #4F60D2 0%, #4F60D2 ${colorChangePercentage}%, #C8CFD6 ${colorChangePercentage}%, #C8CFD6 100%)`,
  };

  return (
    <>
      <Form.Range
        className="d-none d-md-flex"
        value={getValueFromTotalAmount(totalAmount)}
        onChange={onChange}
        step="1"
        min={0}
        max={totalAmountList.length - 1}
        style={trackerColorStyle}
      />
      <div className="amountlist d-none d-md-flex align-items-center justify-content-between mt-3">
        {totalAmountList
          .toSorted((a, b) => a.data - b.data)
          .map(({ data, label }, index) => {
            return (
              <span
                key={data}
                onClick={() => {
                  const fakeEvent = { target: { value: index } };
                  onChange(fakeEvent);
                }}
                className={
                  "price-slider-label" +
                  (data === totalAmount ? " selected" : "")
                }
              >
                {label}
              </span>
            );
          })}
      </div>
    </>
  );
};

export default PriceSlider;
