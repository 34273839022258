import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import { LIGHT_BLUE } from "../color";
import { getItemLocalStorage } from "../utils/helper";
import { LOCAL_STORAGE_KEYS as LOCAL_STORAGE } from "../utils/constants";

const SimulationError = () => {
  const redirectURI = getItemLocalStorage(LOCAL_STORAGE.REDIRECT_URI) ?? "/";
  return (
    <>
      <Col
        xxl={6}
        xl={6}
        lg={6}
        md={7}
        sm={10}
        xs={12}
        className="success-screen d-flex flex-column align-items-center justify-content-center m-auto"
      >
        <div className="success-img mb-4">
          <img src={require("../assets/images/Vector_Error.png")} alt="" />
        </div>
        <h4 className="success-heading text-center mb-3">
          Não foi possível fazer a adesão ao Seguro de Proteção de Pagamentos
        </h4>
        <p className="success-text text-center mb-3">
          Informar o cliente, que não foi possível concluir o processo de adesão
          ao seguro, pois não cumpre os requisitos de elegibilidade.
        </p>
        <Link
          to={redirectURI}
          className="btn text-white home-btn d-xxl-block d-xl-block d-md-block d-sm-none d-none"
          style={{ background: LIGHT_BLUE }}
        >
          OK
        </Link>
      </Col>
    </>
  );
};

export default SimulationError;
