const baseErrorHandler = (errorResponse) => {
  const returnObject = { form: {}, dialog: {}, platform_confirmation: {} };

  const { error } = errorResponse;
  const { n_updated, properties, simulated, customized, custom_data } =
    error ?? {
      n_updated: 0,
      properties: [],
      simulated: {},
      customized: {},
      custom_data: {},
    };
  const text =
    simulated?.error_response?.text ??
    customized?.error_response?.text ??
    custom_data?.error_response?.text ??
    error.text ??
    "";
  const status = error?.status ?? 200;
  const errorCode = error?.code ?? error?.data?.code;
  const contextCode = error?.data?.context_code ?? "";

  const textHasColon = text.includes(":");
  if (textHasColon) {
    const [message, namespace] = text.split(":").map((string) => string.trim());
    const key = namespace || "root";

    returnObject.form[key] = message;
    return returnObject;
  }

  let dialogCode = "";

  if (errorCode === 9034) dialogCode = "insurer_API_error";
  if (errorCode === 9913) dialogCode = "insurer_API_error";
  if (errorCode === 9909) dialogCode = "invalid_request";
  if (errorCode === 9531) dialogCode = "invalid_request";
  if (status === 404) dialogCode = "not_found";
  if (contextCode === 110)
    returnObject.form.nif =
      errorResponse?.error?.data?.text ?? "o NIF inserido é inválido";

  if (dialogCode) returnObject.dialog = { open: true, code: dialogCode };
  else returnObject.form.root = text;

  if (n_updated > 0) {
    returnObject.platform_confirmation = {
      n_updated,
      properties,
    };
  }

  return returnObject;
};

export default baseErrorHandler;
