import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import { LIGHT_BLUE } from "../color";
import { getItemLocalStorage } from "../utils/helper";
import { LOCAL_STORAGE_KEYS as LOCAL_STORAGE } from "../utils/constants";

const SimulationUnavailable = () => {
  const redirectURI = getItemLocalStorage(LOCAL_STORAGE.REDIRECT_URI) ?? "/";
  return (
    <>
      <Col
        xxl={6}
        xl={6}
        lg={6}
        md={7}
        sm={10}
        xs={12}
        className="success-screen d-flex flex-column align-items-center justify-content-center m-auto pt-5"
      >
        <div className="success-img mb-4">
          <img src={require("../assets/images/Vector_Error.png")} alt="" />
        </div>
        <h4 className="success-heading text-center mb-3">
          A Sua simulação não está disponível para edição.
        </h4>
        <p className="success-text text-center mb-3">
          Não foi possível acessar o formulário de edição da sua simulação. Por
          favor entre em contacto conosco.
        </p>
        <p className="error-dialog-text-contact">
          email: <a href="mailto:clientes@habit.pt">clientes@habit.pt</a> ou
          pela linha de atendimento:{" "}
          <a href="tel:+351308804737">+351 308 804 737</a>
        </p>
      </Col>
    </>
  );
};

export default SimulationUnavailable;
