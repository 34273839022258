import { Row, Col, Form, Nav } from "react-bootstrap";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function RightSidebar({ props, previousFunction, submitFunction, canSubmit }) {
  const {
    usertype,
    payment_frequency_name,
    rate_base,
    productname,
    documents,
    policyHolderName,
  } = props;
  return (
    <>
      <Row className="h-100 sidebar-overflow d-none d-md-block">
        <Col className="p-4">
          <h3 className="right-side-heading">Resumo do Seguro</h3>
          {usertype !== "lead" && (
            <div className="montly_value_div d-flex align-items-center justify-content-between">
              <Form.Label className="value-label m-0">
                Valor
                <span className="paymentfrqy"> {payment_frequency_name}</span>
              </Form.Label>
              <span className="monthly_value">{rate_base}€</span>
            </div>
          )}
          <div className="right-sidebar-content">
            <Form.Label className="right-label m-0">Produto</Form.Label>
            <h3 className="right-small-heading">{productname}</h3>
            {usertype !== "lead" && (
              <Fragment>
                <Form.Label className="right-label m-0">Documentos</Form.Label>
                <Nav className="flex-column documents-list" as="ul">
                  {documents &&
                    documents.map((val, index) => {
                      return (
                        <Nav.Item as="li" key={index}>
                          <Link
                            target="_blank"
                            to={val.uri}
                            className="nav-link px-0 d-flex align-items-start"
                            type="button"
                          >
                            <i className="bi bi-file-earmark-text"></i>{" "}
                            {val.name}
                          </Link>
                        </Nav.Item>
                      );
                    })}
                </Nav>
              </Fragment>
            )}
            <Form.Label className="right-label m-0">
              Tomador do Seguro
            </Form.Label>
            {/* <h3 className='right-small-heading'>Habit Analytics PT, S.A.</h3> */}
            <h3 className="right-small-heading">{policyHolderName}</h3>
          </div>
        </Col>
      </Row>
      <div className="d-md-none mobile-footer">
        <div>
          <p>Valor {payment_frequency_name}</p>
          <span>{`${rate_base} €`}</span>
        </div>
        <div>
          {previousFunction !== null && (
            <button
              type="button"
              className="footer-button previous"
              onClick={previousFunction}
            >
              Anterior
            </button>
          )}
          {submitFunction ? (
            <button
              type="button"
              className={"footer-button" + (canSubmit ? "" : " disabled")}
              onClick={canSubmit ? submitFunction : () => {}}
            >
              Continuar
            </button>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default RightSidebar;
