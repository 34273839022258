import { Form } from "react-bootstrap";
import withUIAwareness from "../../UIAwareInput/UIAwareInput";

const FormSelect = ({ specs, value, onChange, showRedBorder }) => {
  const { label, namespace, options } = specs;
  if (
    label === null ||
    label === undefined ||
    namespace === null ||
    namespace === undefined ||
    options === null ||
    options === undefined
  )
    return <></>;

  return (
    <Form.Group className="mb-3">
      <Form.Label className="simulation-form-label m-0 mb-2">
        {label}
      </Form.Label>
      <Form.Select
        className={`${value !== "" ? "selected" : ""} ${
          showRedBorder ? "error-border" : ""
        }`}
        name={namespace}
        value={value}
        onChange={onChange}
      >
        <option value="">Selecionar...</option>
        {options.map(({ data, label }) => {
          return (
            <option key={data} value={data} defaultValue={value === data}>
              {label}
            </option>
          );
        })}
      </Form.Select>
    </Form.Group>
  );
};

export default withUIAwareness(FormSelect);
