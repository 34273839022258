import Login from "../components/Login";
import Print from "../components/forms/Print";
import Home from "../components/UsedBioSafe";
import Form from "../components/SimulationForm";
import Error from "../components/SimulationError";
import Success from "../components/SimulationSuccess";
import Unavailable from "../components/SimulationUnavailable";

const routes = [
  { path: "/", exact: true, name: "Home", component: Home },
  { path: "/login", exact: true, name: "Login", component: Login },
  { path: "/bio", exact: true, name: "Home", component: Home },
  { path: "/print", exact: true, name: "Print", component: Print },
  { path: "/error", exact: true, name: "Error", component: Error },
  { path: "/simulation", exact: true, name: "Form", component: Form },
  { path: "/success", exact: true, name: "Success", component: Success },
  {
    path: "/unavailable",
    exact: true,
    name: "Unavailable",
    component: Unavailable,
  },
];

export default routes;
