export const setUiInitialState = () => ({
  type: "UI/SET_UI_INITIAL_STATE",
  payload: {},
});
export const startAction = (name, params) => ({
  type: "UI/START_ACTION",
  payload: { action: { name, params } },
});
export const stopAction = (name, params) => ({
  type: "UI/STOP_ACTION",
  payload: { action: { name, params } },
});

export const nextStep = () => ({
  type: "UI/NEXT_STEP",
  payload: {},
});

export const previousStep = () => ({
  type: "UI/PREVIOUS_STEP",
  payload: {},
});

export const setStep = (step) => ({
  type: "UI/SET_STEP",
  payload: step,
});

export const openErrorDialog = (code) => ({
  type: "UI/ERROR_OPEN_DIALOG",
  payload: code,
});

export const closeErrorDialog = () => ({
  type: "UI/ERROR_CLOSE_DIALOG",
  payload: {},
});

export const addErrorMessageToNamespace = (data) => ({
  type: "UI/ADD_ERROR_MESSAGE_TO_NAMESPACE",
  payload: data,
});

export const clearErrorForm = () => ({
  type: "UI/ERROR_CLEAR_FORM",
  payload: {},
});

export const definePaymentData = (name, data, updated) => ({
  type: "UI/DEFINE_PAYMENT_DATA",
  payload: { method: name, ...data, last_updated: updated },
});

export const setTheme = (theme) => ({
  type: "UI/SET_THEME",
  payload: theme,
});

export const setSmartlinkHash = (hash) => ({
  type: "UI/SET_SMARTLINK_HASH",
  payload: hash,
});
