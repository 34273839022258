import { Form } from "react-bootstrap";
import withUIAwareness from "../../UIAwareInput/UIAwareInput";

const FormCheck = ({ specs, value, onChange, showRedBorder }) => {
  const { label, namespace } = specs;
  const inputClassName = showRedBorder ? "error-border" : "";

  const dateToday = `${String(new Date().getDate()).padStart(2, "0")}-${String(
    new Date().getMonth() + 1
  ).padStart(2, "0")}-${new Date().getFullYear()}`.replaceAll("-", "/");

  const formattedLabel = () => {
    if (typeof label === "string")
      return label.replaceAll("{dateToday}", dateToday);
    return label;
  };

  return (
    <Form.Group className={showRedBorder ? "" : "mb-3"}>
      <Form.Check
        name={namespace}
        className={inputClassName}
        id={`${namespace}_uid`}
        type="checkbox"
        value={value}
        checked={value}
        onChange={onChange}
        label={formattedLabel()}
      />
    </Form.Group>
  );
};

export default withUIAwareness(FormCheck);
