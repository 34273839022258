import { Outlet } from "react-router-dom";
// import Header from "./Header";


const AppLayout = () => {
    return(
        <>
            <main>
                <Outlet/>
            </main>
        </>
    )
}

export default AppLayout;