import { isNullOrUndefined } from "../components/forms/utils";
import { Fragment } from "react";

export const treatPropertiesArrayFromResponse = (propertiesArray) => {
  const treatedArray = propertiesArray.map((property) => {
    const treatedProperty = {
      data: property.data,
      namespace: property.namespace,
    };
    if (!isNullOrUndefined(property.label))
      treatedProperty.label = property.label;
    if (!isNullOrUndefined(property.options))
      treatedProperty.options = property.options;
    return treatedProperty;
  });

  return { properties: treatedArray };
};

export const setDistributionDetails = (
  type,
  distributionpoint_id,
  distributorseller_id,
  storedURL
) => {
  const getSourceUriFromLocation = (url) => {
    const lastSlashIndex = url?.lastIndexOf("/");
    return url?.substring(0, lastSlashIndex);
  };
  const sourceUri =
    getSourceUriFromLocation(storedURL) ??
    getSourceUriFromLocation(window.location.href);

  const typeIsGuestOrNull =
    type === "guest" || type === null || type === undefined || type === "";

  const flow = typeIsGuestOrNull ? "guest" : type;
  const distributionPointId = typeIsGuestOrNull
    ? ""
    : distributionpoint_id ?? "";
  const distributionSellerId = typeIsGuestOrNull
    ? ""
    : distributorseller_id ?? "";

  const distributionDetails = {
    flow: flow,
    distributionpoint: { id: distributionPointId },
    seller: { id: distributionSellerId },
    source_uri: sourceUri,
  };

  return distributionDetails;
};

export const setItemLocalStorage = (key, value) => {
  return localStorage.setItem(key, value);
  // if (window.top === window.self) {
  //   return localStorage.setItem(key, value);
  // } else {
  //   const message = JSON.stringify({
  //     message: "setItemLocalStorage",
  //     key: key,
  //     value: value,
  //   });
  //   return window.parent.postMessage(message, "*");
  // }
};

export const getItemLocalStorage = (key) => {
  return localStorage.getItem(key);
  // if (window.top === window.self) {
  //   return localStorage.getItem(key);
  // } else {
  //   const message = JSON.stringify({
  //     message: "getItemLocalStorage",
  //     key: key,
  //   });
  //   return window.parent.postMessage(message, "*");
  // }
};

export const removeItemLocalStorage = (key) => {
  return localStorage.removeItem(key);
  // if (window.top === window.self) {
  //   return localStorage.removeItem(key);
  // } else {
  //   const message = JSON.stringify({
  //     message: "removeLocalStorageKey",
  //     key: key,
  //   });
  //   return window.parent.postMessage(message, "*");
  // }
};

export const clearLocalStorage = () => {
  return localStorage.clear();
  // if (window.top === window.self) {
  //   return localStorage.clear();
  // } else {
  //   localStorage.clear();
  //   const message = JSON.stringify({
  //     message: "clearLocalStorage",
  //     key: "",
  //     value: "",
  //   });
  //   return window.parent.postMessage(message, "*");
  // }
};

export const formatDateToApi = (date) => {
  if (!date) return "";

  const YYYY = date.getFullYear();
  const MM = String(date.getMonth() + 1).padStart(2, "0");
  const DD = String(date.getDate()).padStart(2, "0");
  return `${YYYY}-${MM}-${DD}`;
};

export const formatApiToDate = (dateString) => {
  if (!dateString) return null;

  const [YYYY, MM, DD] = dateString.split("-");
  return new Date(YYYY, MM - 1, DD);
};

export const formatTextWithLineBreaksToJSX = (text) => {
  const lines = text.split("\n");
  return lines.map((line, index) => {
    const isLastLine = index === lines.length - 1;
    return (
      <Fragment key={index}>
        {line}
        {!isLastLine && <br />}
      </Fragment>
    );
  });
};
export const getUserData = (userId, email, name) => {
  const existingUser = { user_id: userId };
  if (userId) return existingUser;

  const newUser = { user: { email: email, name: name } };
  if (email && name) return newUser;

  const noUser = {};
  return noUser;
};
