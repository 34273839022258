import { formatApiToDate } from "../../utils/helper";
import { CHECKMARK_LABELS } from "../../utils/constants";

export const getDateToday = () => {
  const today = new Date();
  const YYYY = today.getFullYear();
  const MM = String(today.getMonth() + 1).padStart(2, "0");
  const DD = String(today.getDate()).padStart(2, "0");

  return `${DD}/${MM}/${YYYY}`;
};

export const isNullOrUndefined = (value) =>
  value === null || value === undefined;

export const getSpecsByNamespaceAndAuthRes = (
  mainArray,
  namespace,
  authRes
) => {
  if (authRes === null || authRes === undefined) return null;

  const hardCodedSpecs = [
    {
      namespace: "confirmation_1",
      label:
        "Confirmo que a entidade final autoriza a partilha dos dados com a Habit para fins de apresentação de uma proposta de seguro.",
    },
    {
      namespace: "confirmation_2",
      label:
        "Tomei conhecimento que estou numa posição de afiliado para a recomendação de clientes e que, não sendo mediador de seguros, não posso fazer qualquer aconselhamento sobre o produto de seguro, as suas características, coberturas ou prémios.",
    },
    {
      namespace: "receive_email",
      label: "O cliente aceita receber comunicações de marketing",
    },
    {
      namespace: "physically_signed",
      label: "Confirmo que o cliente assinou o contrato de seguro",
    },
  ];
  if (authRes.quote_specs === null || authRes.quote_specs === undefined)
    return null;

  const quoteSpecsArray =
    mainArray === "hardCodedSpecs"
      ? hardCodedSpecs
      : authRes.quote_specs.specs[mainArray];

  const matchingSpecs = quoteSpecsArray.find(
    (inputSpecs) => inputSpecs.namespace === namespace
  );

  return matchingSpecs || null;
};

export const setDateProperty = (namespace, properties, setFunction) => {
  const foundProperty = properties.find(
    ({ namespace: matchNamespace }) => matchNamespace === namespace
  );
  setFunction(formatApiToDate(foundProperty?.data));
};

export const setOptionsAndDataProperty = (
  namespace,
  properties,
  setOptionsFunction,
  setDataFunction
) => {
  const foundProperty = properties.find((item) => item.namespace === namespace);
  if (setDataFunction !== null) setDataFunction(foundProperty?.data);
  if (setOptionsFunction !== null) setOptionsFunction(foundProperty?.options);
};

export const getRateBaseForAmountAndFrequency = (
  rateBaseTable,
  insuredAmount,
  paymentFrequency
) => {
  const foundObject = rateBaseTable.find(
    (object) => object.total_amount === insuredAmount
  );
  if (!isNullOrUndefined(foundObject)) {
    const rateBaseForFrequency = foundObject[paymentFrequency];
    if (!isNullOrUndefined(rateBaseForFrequency)) return rateBaseForFrequency;
  }
  return null;
};

export const getAvailableFrequencies = (
  frequencyList,
  rateBaseTable,
  amount
) => {
  const rateBaseRef = rateBaseTable.find((ref) => ref.total_amount === amount);
  const availableFrequencies = frequencyList.filter((frequency) => {
    const isAvailable = Boolean(rateBaseRef[frequency.data]);
    return isAvailable;
  });

  return (availableFrequencies.length > 0 && availableFrequencies) || [];
};

export const getCurrentOrLastFrequency = (
  frequencyList,
  rateBaseTable,
  amount,
  currentFrequency
) => {
  const rateBaseRef =
    rateBaseTable.find((ref) => ref.total_amount === amount) ?? {};

  const availableFrequencies = frequencyList.filter(({ data }) =>
    rateBaseRef.hasOwnProperty(data)
  );

  const currentOrLastFrequency =
    availableFrequencies.find((match) => match.data === currentFrequency) ??
    availableFrequencies[availableFrequencies.length - 1];

  return currentOrLastFrequency;
};

export const getObjectByNamespaceFromArray = (namespace, array) => {
  //sometimes just the name of the array goes onto this fucntion due to bad planning on the logic
  if (!Array.isArray(array))
    return { namespace: namespace, data: "", label: "" };

  const dataObject = array.find((match) => match.namespace === namespace);
  return dataObject ?? { namespace: namespace, data: "", label: "" };
};

export const getDataByNamespace = (namespace, mainArray) => {
  const dataObject = getObjectByNamespaceFromArray(namespace, mainArray);

  return dataObject?.data ?? "";
};

export const getChosenOptionLabelForNamespace = (namespace, mainArray) => {
  const dataObject = getObjectByNamespaceFromArray(namespace, mainArray);
  const { data: chosenData, options: availableOptions } = dataObject;
  const matchOption = availableOptions?.find(
    (match) => match.data === chosenData
  );

  return matchOption?.label ?? "";
};

export const getSpecsByNamespace = (namespace, mainArray) => {
  const specsFromQuote = getObjectByNamespaceFromArray(namespace, mainArray);

  const returnObject = {
    namespace: specsFromQuote.namespace,
    label: specsFromQuote?.label ?? "",
  };
  if (specsFromQuote.hasOwnProperty("options"))
    returnObject.options = specsFromQuote.options;

  return returnObject;
};

export const getSpecsForCheckBox = (namespace, mainArray, flow) => {
  const specsFromQuote = getObjectByNamespaceFromArray(namespace, mainArray);
  const hasSpecs = CHECKMARK_LABELS.hasOwnProperty(namespace);
  const renderedLabel =
    (hasSpecs
      ? CHECKMARK_LABELS?.[namespace]?.[flow]
      : specsFromQuote?.label) ?? "texto não encontrado";
  return {
    namespace: namespace,
    label: renderedLabel.replace("{DATE_TODAY}", getDateToday()),
  };
};
