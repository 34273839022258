export const LOCAL_STORAGE_KEYS = {
  FINGERPRINT: "invoice-fingerprint",
  ACCESS_TOKEN: "invoice-access_token",
  TYPE: "invoice-flow_type",
  DISTRIBUTION_DETAILS: "invoice-distribution_details",
  REDIRECT_URI: "invoice-redirect_uri",
};

export const ERROR_DIALOG_PROPS = {
  not_found: {
    image: require("../assets/images/Vector_Error.png"),
    title: "404",
    description:
      "Não conseguimos realizar sua chamada. Verifique sua conexão e tente novamente.",
  },
  insurer_API_error: {
    image: require("../assets/images/Vector_Error.png"),
    title: "Erro com a seguradora",
    description: "Não temos simulação disponível. Tente novamente mais tarde.",
  },
  invalid_request: {
    image: require("../assets/images/Vector_Error.png"),
    title: "Pedido Inválido",
    description:
      "Tem algo de errado com a sua requisição, favor verificar os dados inseridos e tente novamente. Se o problema persistir, entre em contacto conosco.",
  },
  default: {
    image: require("../assets/images/Vector_Error.png"),
    title: "Ooops...",
    description: "Algo errado aconteceu. Tente novamente mais tarde.",
  },
};

export const SUCCESS_PAGE_PROPS = {
  lead: {
    image: require("../assets/images/Info_Circle.png"),
    success_heading:
      "O pedido de simulação ao Seguro de Proteção de Pagamentos foi feito com sucesso!",
    success_text:
      "Informar o cliente, que irá receber por email ou SMS o link para finalizar a simulação.",
  },
  default: {
    image: require("../assets/images/Vector_(Stroke).png"),
    success_heading:
      "O pedido de adesão ao Seguro de Proteção de Pagamentos foi feito com sucesso!",
    success_text:
      "No email poderá encontrar toda a informação pré-contratual bem como os documentos contratuais do seu novo seguro (apólice).\n Obrigado pela escolha!",
  },
};

export const CHECKMARK_LABELS = {
  is_working: {
    seller:
      "O cliente confirmou que atualmente se encontra com contrato de trabalho.",
    lead: "O cliente confirmou que atualmente se encontra com contrato de trabalho.",
    user: "Confirmo que atualmente me encontro com contrato de trabalho.",
    guest: "Confirmo que atualmente me encontro com contrato de trabalho.",
  },

  date_confirmation: {
    seller: "O cliente confirma que a fatura tem a data de hoje ({DATE_TODAY})",
    lead: "O cliente confirma que a fatura tem a data de hoje ({DATE_TODAY})",
    user: "Confirmo que a fatura tem a data de hoje ({DATE_TODAY})",
    guest: "Confirmo que a fatura tem a data de hoje ({DATE_TODAY})",
  },

  receive_email_marketing: {
    seller:
      "O cliente consente que utilizem os seus dados para receber promoções ou campanhas.",
    lead: "O cliente consente que utilizem os seus dados para receber promoções ou campanhas.",
    user: "Consinto que utilizem os meus dados para receber promoções ou campanhas.",
    guest:
      "Consinto que utilizem os meus dados para receber promoções ou campanhas.",
  },

  //TODO: this one is not being used and is hard coded on the JSX itself. See logic for handling links.
  data_sharing_confirmation: {
    seller:
      "Confirmar cedência dos dados pessoais à Habit, cuja {PRVACY_POLICY}",
    lead: "Confirmar cedência dos dados pessoais à Habit, cuja {PRVACY_POLICY}",
    user: "Confirmo cedência dos dados pessoais à Habit, cuja {PRVACY_POLICY}",
    guest: "Confirmo cedência dos dados pessoais à Habit, cuja {PRVACY_POLICY}",
  },

  confirmation_1: {
    seller:
      "Confirmo que a entidade final autoriza a partilha dos dados com a Habit para fins de apresentação de uma proposta de seguro.",
    lead: "Confirmo que a entidade final autoriza a partilha dos dados com a Habit para fins de apresentação de uma proposta de seguro.",
    user: "",
    guest: "",
  },

  confirmation_2: {
    seller:
      "Tomei conhecimento que estou numa posição de afiliado para a recomendação de clientes e que, não sendo mediador de seguros, não posso fazer qualquer aconselhamento sobre o produto de seguro, as suas características, coberturas ou prémios.",
    lead: "Tomei conhecimento que estou numa posição de afiliado para a recomendação de clientes e que, não sendo mediador de seguros, não posso fazer qualquer aconselhamento sobre o produto de seguro, as suas características, coberturas ou prémios.",
    user: "",
    guest: "",
  },

  physically_signed: {
    seller: "Confirmo que o cliente assinou o contrato de seguro",
    lead: "Confirmo que o cliente assinou o contrato de seguro",
    user: "Confirmo que o cliente assinou o contrato de seguro",
    guest: "Confirmo que o cliente assinou o contrato de seguro",
  },
};
