import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ERROR_DIALOG_PROPS } from "../utils/constants";
import { UIactions } from "../actions/index";

const ErrorDialog = ({ dialog }) => {
  const dispatch = useDispatch();
  const { open, code } = dialog;
  const { image, title, description } = ERROR_DIALOG_PROPS[code ?? "default"];

  return (
    <Modal
      show={open}
      backdrop="static"
      keyboard={false}
      animation={false}
      centered
    >
      <Modal.Body className="d-flex flex-column align-items-center error-dialog-container">
        <img src={image} alt="error" className="img-error" />
        <p className="error-dialog-text-title">{title}</p>
        <p className="error-dialog-text-description">{description}</p>
        <p className="error-dialog-text-contact">
          email: <a href="mailto:clientes@habit.pt">clientes@habit.pt</a> ou
          pela linha de atendimento:{" "}
          <a href="tel:+351308804737">+351 308 804 737</a>
        </p>
        <button
          className="error-dialog-button"
          onClick={() => dispatch(UIactions.closeErrorDialog())}
        >
          OK
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default ErrorDialog;
