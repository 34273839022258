const intialState = "";
const clearPersist = (state = intialState, action)=>{
    switch(action.type){
        case "CLEAR_REDUX_STORE":
            localStorage.removeItem('persist:root');
            return intialState;
        default:
            return state;
    }
}

export default clearPersist;