import { ThreeDots } from 'react-loader-spinner';
import { Fragment, useEffect, useState } from 'react';

const Print = ()=>{
    const [pdf, setPdf] = useState('');
    const [isLoader, setisLoader] = useState(false);
    
    useEffect(()=>{
        if(pdf === ""){
            setPdf(localStorage.getItem('pdf'));
        }

        setisLoader(true);
        setTimeout(function(){
            setisLoader(false);
            window.print();
        }, 5000);
    }, [pdf])

    return (
        <Fragment>
            {(isLoader === true &&
                <ThreeDots
                    height="90"
                    width="90"
                    radius="9"
                    color="#FDBE14"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass="ajax-loader"
                    visible={true}
                />
            )}
            
            <iframe type='application/pdf' id="printf" name="printf" src={pdf} style={{width:'100%', height:'100vh'}}></iframe>
        </Fragment>
    )
}

export default Print;