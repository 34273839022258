import PhoneInputComponent from "react-phone-number-input";
import { Form } from "react-bootstrap";
import withUIAwareness from "../../UIAwareInput/UIAwareInput";

const PhoneInput = ({ specs, value, onChange, showRedBorder }) => {
  const { label, namespace } = specs;

  return (
    <Form.Group className="mb-3">
      <Form.Label className="simulation-form-label m-0 mb-2">
        {label}
      </Form.Label>
      <PhoneInputComponent
        name={namespace}
        className={`${showRedBorder ? "error-border" : ""}`}
        placeholder="000 000 000"
        defaultCountry={"PT"}
        value={value}
        onChange={onChange}
        options={["PT"]}
      />
    </Form.Group>
  );
};

export default withUIAwareness(PhoneInput);
