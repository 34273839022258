export const setID = (data) => ({
  type: "QUOTE/SET_ID",
  payload: data,
});

export const update = (data) => ({
  type: "QUOTE/UPDATE",
  payload: data,
});

export const clear = () => ({
  type: "QUOTE/CLEAR",
  payload: {},
});
