const initialState = {
  id: '',
  name: '',
  email: '',
  flags: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER/UPDATE':
      return {
        ...state,
        ...action.payload,
      };

    case 'USER/CLEAR':
      return initialState;

    default:
      return state;
  }
};

export default userReducer;
