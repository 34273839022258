const baseRequestHandler = (request) => async (params) => {
  try {
    const response = await request(params);
    const errorResponse =
      response.data?.simulated?.error_response ??
      response.data?.custom_data?.error_response ??
      response.data?.customized?.error_response ??
      false;
    if (errorResponse) throw response.data;

    return { type: "success", data: response.data };
  } catch (error) {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    return { type: "error", error: error.response ?? error };
  }
};

export default baseRequestHandler;

// example:
// const getData = baseRequestHandler((params) => axios.get("/api/", { params }));
// const dataResponse = await getData({ foo: "foo" });

// const getSomethingWithPayloadAndHeaders = (({params, config}) => axios.get("base/api/endpoint", params, config));
// const response = await getSomethingWithPayloadAndHeaders({ params: payload, config: { headers: headers } });
