import { Form } from "react-bootstrap";
import DatePickerInput from "react-date-picker";
import withUIAwareness from "../../UIAwareInput/UIAwareInput";

const DatePicker = ({ specs, value, dateSetterFunction, showRedBorder }) => {
  const { label, namespace } = specs;
  const inputClassName = `form-control ${value === "" ? "" : "selected"} ${
    showRedBorder ? "error-border" : ""
  }`;

  return (
    <Form.Group className={showRedBorder ? "" : "mb-3"}>
      <Form.Label className="simulation-form-label m-0 mb-2">
        {label}
      </Form.Label>
      <DatePickerInput
        format="dd-MM-yyyy"
        className={inputClassName}
        value={value}
        name={namespace}
        locale="pt"
        dayPlaceholder="dd"
        monthPlaceholder="mm"
        yearPlaceholder="yyyy"
        onChange={(date) => {
          dateSetterFunction(date);
        }}
        openCalendarOnFocus={false}
      />
    </Form.Group>
  );
};

export default withUIAwareness(DatePicker);
