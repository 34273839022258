const initialState = {
  id: null,
  properties: [],
};

const protectedAssetReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'PROTECTED_ASSET/SET_ID':
      return {...state, id: action.payload};

    case 'PROTECTED_ASSET/UPDATE':

      const updatedProperties = state.properties.map(existingProperty => {
        const matchingProperty = action.payload.properties.find(updatedProperty =>
          updatedProperty.namespace === existingProperty.namespace
        );
        if (matchingProperty !== null && matchingProperty !== undefined) {
          return { ...existingProperty, ...matchingProperty };
        }
        return existingProperty;
      });

      const newProperties = action.payload.properties.filter(updatedProperty =>
        !state.properties.find(existingProperty => existingProperty.namespace === updatedProperty.namespace)
      );

      return {
        ...state,
        properties: [...updatedProperties, ...newProperties],
      };

    case 'PROTECTED_ASSET/CLEAR':
      return initialState;

    default:
      return state;
  }
};

export default protectedAssetReducer;
