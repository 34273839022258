const initialState = {};

const insuranceProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INSURANCE_PRODUCT/UPDATE":
      return { ...state, ...action.payload };

    case "INSURANCE_PRODUCT/CLEAR":
      return initialState;
    default:
      return state;
  }
};

export default insuranceProductReducer;
