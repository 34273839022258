const initialState = {
  flow: "",
  distributionpoint: { id: "" },
  seller: { id: "" },
}

const distributionDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DISTRIBUTION_DETAILS/UPDATE":
      return { ...state, ...action.payload }

    case "DISTRIBUTION_DETAILS/CLEAR":
      return initialState;

    default:
      return state;
  }
}

export default distributionDetailsReducer;