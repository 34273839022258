export const setID = (data) => ({
  type: "POLICY_HOLDER/SET_ID",
  payload: data,
});

export const update = (data) => ({
  type: "POLICY_HOLDER/UPDATE",
  payload: data,
});

export const clear = () => ({
  type: "POLICY_HOLDER/CLEAR",
  payload: {},
});
