import {
  policyHolderActions,
  userActions,
  quoteActions,
  UIactions,
} from "../../actions/index";
import { MAIN_COLOR, SECONDARY_COLOR } from "../../config";
import {
  getItemLocalStorage,
  getUserData,
  setItemLocalStorage,
  treatPropertiesArrayFromResponse,
} from "../../utils/helper";
import { Row, Col, Form, Nav, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { useEffect, useState } from "react";
import React from "react";
import axios from "axios";
import RightSidebar from "./RightSidebar";
import FormControl from "./shared/FormControl";
import {
  getChosenOptionLabelForNamespace,
  getDataByNamespace,
  getObjectByNamespaceFromArray as getObjectByNamespace,
  getSpecsByNamespace,
  getSpecsForCheckBox,
  isNullOrUndefined,
} from "./utils";
import FormCheck from "./shared/FormCheck";
import FormRadio from "./shared/FormRadio";
import FormSelect from "./shared/FormSelect";
import PhoneInput from "./shared/PhoneInput";
import InputMask from "./shared/InputMask";
import SubmitButton from "./shared/SubmitButton";
import { LOCAL_STORAGE_KEYS as LOCAL_STORAGE } from "../../utils/constants";
import baseRequestHandler from "../../services/baseRequestHandler";
import {
  getDocsSign,
  policyHolders,
  requestSMSCode,
  updateQuoteProperties,
} from "../../api/requests";
import baseErrorHandler from "../../services/baseErrorHandler";

const BeneficiaryData = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const style = { color: "#C8CFD6" };
  const customData = useSelector((state) => state.customData);
  const insuranceProduct = useSelector((state) => state.insuranceProduct);

  const quoteProperties = useSelector((state) => state.quote.properties);
  const policyHolderProperties = useSelector(
    (state) => state.policyHolder.properties
  );
  const { id: userID } = useSelector((state) => state.user);

  const quoteID = useSelector((state) => state.quote.id);
  const policyHolderID = useSelector((state) => state.policyHolder.id);

  const data_sharing_document = useSelector(
    (state) => state.insuranceProduct?.documents?.at_privacy_policy_consent[0]
  ) ?? { name: "", uri: "" };

  const UI = useSelector((state) => state.UI);
  const loaderActions = UI?.loader?.actions ?? [];

  const isLoader = loaderActions.length > 0;

  const [cc, setCC] = useState("");
  const [pdf, setPdf] = useState("");
  const [sms, setSMS] = useState("");
  const [city, setCity] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [mobile, setMobile] = useState("");
  const setNewMobile = (value) => {
    setSmssent(false);
    setMobile(value);
  };
  const [address, setAddress] = useState("");
  const [sms_sent, setSmssent] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cc_country, setCCcountry] = useState("PT");
  const [postal_code, setPostalcode] = useState("");
  const [nationality, setNationality] = useState("PT");
  const [signature_type, setSignaturetype] = useState("");
  const [receive_email_marketing, setReceiveEmailMarketing] = useState(false);
  const [data_sharing_confirmation, setDataSharingConfirmation] =
    useState(false);
  const [globalDisclaimer, setGlobalDisclaimer] = useState([]);
  const [productDisclaimer, setProductDisclaimer] = useState("");
  const [physically_signed, setPhysicallysigned] = useState(false);
  const [docProcessStatus, setDocProcessStatus] = useState(false);

  const userType = getItemLocalStorage(LOCAL_STORAGE.TYPE);
  const fingerprint = getItemLocalStorage(LOCAL_STORAGE.FINGERPRINT);
  const access_token = getItemLocalStorage(LOCAL_STORAGE.ACCESS_TOKEN);

  const signatureTypes = getObjectByNamespace(
    "signature_type",
    quoteProperties
  )?.options;
  const flowNeedsSignature = signatureTypes && signatureTypes.length > 0;

  const sidebarProps = {
    usertype: userType,
    payment_frequency_name: getChosenOptionLabelForNamespace(
      "payment_frequency",
      quoteProperties
    ),
    rate_base: getDataByNamespace("rate_base", quoteProperties),
    productname: insuranceProduct.name,
    documents: insuranceProduct.documents.at_offer,
    policyHolderName: customData.policyholder_name,
  };

  const docProcess = async () => {
    setFormDataUI();

    const isOK =
      (await didPolicyHoldersAsync()) &&
      (await UpdateQuoteSignatureTypeAsync()) &&
      (await getDocsToSignAsync());

    setDocProcessStatus(isOK);
  };

  const getDocsToSignAsync = async () => {
    dispatch(UIactions.startAction("GET_DOCS_SIGN", {}));
    const response = await getDocsSign(
      fingerprint,
      userType,
      access_token,
      quoteID
    );
    if (response.type === "error") {
      dispatch(UIactions.stopAction("GET_DOCS_SIGN", {}));
      const { form, dialog } = baseErrorHandler(response);
      if (dialog?.open ?? false) dispatch(UIactions.openErrorDialog(dialog));
      if (Object.keys(form).length > 0) {
        dispatch(UIactions.addErrorMessageToNamespace(form));
      }
      return false;
    }

    setPdf(response.data.custom_data.url);
    setItemLocalStorage("pdf", response.data.custom_data.url);
    const pdfWindow = window.open(`${response.data.custom_data.url}`, `_blank`);
    if (pdfWindow === null)
      console.log("Please allow pop-ups to print the document");
    //TODO: replace with toast or dialog
    else
      pdfWindow.addEventListener("documentloaded", () => {
        pdfWindow.print();
        pdfWindow.close();
      });

    dispatch(UIactions.stopAction("GET_DOCS_SIGN", {}));
    return true;
  };

  const confirmDigitalSignatureReceivedAsync = async (data) => {
    dispatch(UIactions.startAction("QUOTE_UPDATE_PROPERTIES", {}));
    const updatedProperties = [
      {
        namespace: "digital_signature_received",
        data: data,
      },
    ];

    const response = await updateQuoteProperties(
      fingerprint,
      userType,
      access_token,
      quoteID,
      updatedProperties
    );
    if (response.type === "error") {
      dispatch(UIactions.stopAction("QUOTE_UPDATE_PROPERTIES", {}));
      const { form, dialog } = baseErrorHandler(response);
      if (dialog?.open ?? false) dispatch(UIactions.openErrorDialog(dialog));
      if (Object.keys(form).length > 0) {
        dispatch(UIactions.addErrorMessageToNamespace(form));
      }
      //TODO: tell about wrong SMS
      return false;
    }

    const quoteUpdates = treatPropertiesArrayFromResponse([
      ...(response?.data?.simulated?.quote_properties ?? []),
      ...(response?.data?.customized?.quote_properties ?? []),
    ]);

    dispatch(quoteActions.update(quoteUpdates));

    const smartlinkHash = response?.data?.simulated?.smart_link?.hash;
    if (smartlinkHash) dispatch(UIactions.setSmartlinkHash(smartlinkHash));

    dispatch(UIactions.stopAction("QUOTE_UPDATE_PROPERTIES", {}));
    if (userType === "lead") navigate("/success");
    else dispatch(UIactions.nextStep());
    return true;
  };

  const didPolicyHoldersAsync = async () => {
    dispatch(UIactions.startAction("POLICY_HOLDERS", {}));
    const updatedPolicyHolderProperties = [
      { namespace: "name", data: name },
      { namespace: "gender", data: gender },
      { namespace: "cc", data: cc },
      { namespace: "cc_country", data: cc_country },
      { namespace: "nationality", data: nationality },
      { namespace: "mobile", data: mobile },
      { namespace: "email", data: email },
      { namespace: "address", data: address },
      { namespace: "postal_code", data: postal_code },
      { namespace: "city", data: city },
      {
        namespace: "receive_email_marketing",
        data: receive_email_marketing.toString(),
      },
    ];
    const userData = getUserData(userID ?? "", email ?? "", name ?? "");

    const response = await policyHolders(
      fingerprint,
      userType,
      access_token,
      quoteID,
      updatedPolicyHolderProperties,
      userData,
      policyHolderID
    );
    if (response.type === "error") {
      dispatch(UIactions.stopAction("POLICY_HOLDERS", {}));
      const { form, dialog } = baseErrorHandler(response);
      if (dialog?.open ?? false) dispatch(UIactions.openErrorDialog(dialog));
      if (Object.keys(form).length > 0) {
        dispatch(UIactions.addErrorMessageToNamespace(form));
      }
      return false;
    }

    dispatch(
      userActions.update({
        id: response.data.user_id ?? userID ?? "",
        name: name,
        email: email,
      })
    );

    const smartlinkHash = response?.data?.simulated?.smart_link?.hash;
    if (smartlinkHash) dispatch(UIactions.setSmartlinkHash(smartlinkHash));

    dispatch(UIactions.stopAction("POLICY_HOLDERS", {}));
    return true;
  };

  const UpdateQuoteSignatureTypeAsync = async () => {
    dispatch(UIactions.startAction("QUOTE_UPDATE_PROPERTIES", {}));
    const updatedProperties = [
      {
        namespace: "signature_type",
        data: signature_type,
      },
    ];

    const response = await updateQuoteProperties(
      fingerprint,
      userType,
      access_token,
      quoteID,
      updatedProperties
    );
    if (response.type === "error") {
      dispatch(UIactions.stopAction("QUOTE_UPDATE_PROPERTIES", {}));
      const { form, dialog } = baseErrorHandler(response);
      if (dialog?.open ?? false) dispatch(UIactions.openErrorDialog(dialog));
      if (Object.keys(form).length > 0) {
        dispatch(UIactions.addErrorMessageToNamespace(form));
      }
      return false;
    }

    const quoteProperties = response.data?.quote_properties ?? [];
    const simulatedQuoteProperties =
      response.data?.simulated?.quote_properties ?? [];

    dispatch(
      quoteActions.update({
        properties: [...quoteProperties, ...simulatedQuoteProperties],
      })
    );

    const smartlinkHash = response?.data?.simulated?.smart_link?.hash;
    if (smartlinkHash) dispatch(UIactions.setSmartlinkHash(smartlinkHash));

    dispatch(UIactions.stopAction("QUOTE_UPDATE_PROPERTIES", {}));
    return true;
  };

  const getSMSAsync = async () => {
    dispatch(UIactions.startAction("REQUEST_SMS_CODE", {}));
    setSmssent(false);

    const response = await requestSMSCode(
      fingerprint,
      userType,
      access_token,
      quoteID
    );
    if (response.type === "error") {
      dispatch(UIactions.stopAction("REQUEST_SMS_CODE", {}));
      const { form, dialog } = baseErrorHandler(response);
      if (dialog?.open ?? false) dispatch(UIactions.openErrorDialog(dialog));
      dispatch(
        UIactions.addErrorMessageToNamespace({
          mobile:
            "Não foi possível enviar SMS para esse número. Verifique e tente novamente.",
        })
      );
      if (Object.keys(form).length > 0) {
        dispatch(UIactions.addErrorMessageToNamespace(form));
      }
      return false;
    }

    setSmssent(response.data?.custom_data?.sms_sent ?? false);
    dispatch(UIactions.stopAction("REQUEST_SMS_CODE", {}));
    return true;
  };

  const getFlow = () => {
    const breakFlow = () => {
      return false;
    };

    const flowState = {
      shouldSubmit: false,
      onSubmit: breakFlow,
      onNotSubmit: breakFlow,
    };

    const flowNeedsSMS = signature_type === "digital";
    const flowNeedsPdf = signature_type === "physical";
    const flowDoesntNeedSignature = !flowNeedsSignature;

    if (flowNeedsSMS) {
      flowState.shouldSubmit = sms_sent === true;
      flowState.onSubmit = async () =>
        await confirmDigitalSignatureReceivedAsync(sms);
      flowState.onNotSubmit = async () => {
        const isOK =
          (await didPolicyHoldersAsync()) &&
          (await UpdateQuoteSignatureTypeAsync()) &&
          (await getSMSAsync());
        return isOK;
      };
    }

    if (flowNeedsPdf) {
      flowState.shouldSubmit = docProcessStatus === true;
      flowState.onSubmit = async () =>
        await confirmDigitalSignatureReceivedAsync(
          physically_signed === true ? "true" : "false"
        );
      flowState.onNotSubmit = () => breakFlow();
    }

    if (flowDoesntNeedSignature) {
      flowState.shouldSubmit = true;
      flowState.onSubmit = async () => {
        const isOK = await didPolicyHoldersAsync();
        if (isOK) {
          if (userType === "lead") navigate("/success");
          else dispatch(UIactions.nextStep());
        }
        return isOK;
      };
      flowState.onNotSubmit = breakFlow;
    }

    return flowState;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(UIactions.clearErrorForm());

    const { shouldSubmit, onSubmit, onNotSubmit } = getFlow();

    setFormDataUI();

    if (shouldSubmit) onSubmit();
    else onNotSubmit();

    return true;
  };

  const setFormDataUI = () => {
    const policyHolderData = [
      { namespace: "name", data: name },
      { namespace: "gender", data: gender },
      { namespace: "cc", data: cc },
      { namespace: "cc_country", data: cc_country },
      { namespace: "nationality", data: nationality },
      { namespace: "mobile", data: mobile },
      { namespace: "email", data: email },
      { namespace: "address", data: address },
      { namespace: "postal_code", data: postal_code },
      { namespace: "city", data: city },
      {
        namespace: "receive_email_marketing",
        data: receive_email_marketing.toString(),
      },
    ];

    dispatch(policyHolderActions.update({ properties: policyHolderData }));

    dispatch(
      quoteActions.update({
        properties: [{ namespace: "signature_type", data: signature_type }],
      })
    );
  };

  // setCountries
  const getCountriesList = async () => {
    dispatch(UIactions.startAction("GET_COUNTRIES", {}));
    const url = "https://cdn.habit.io/insurance-products/country_code_pt.json";
    const countriesRequest = baseRequestHandler(() => axios.get(url));
    const response = await countriesRequest();
    if (response.type === "error") {
      dispatch(UIactions.stopAction("GET_COUNTRIES", {}));
      const { form, dialog } = baseErrorHandler(response);
      if (dialog?.open ?? false) dispatch(UIactions.openErrorDialog(dialog));
      if (Object.keys(form).length > 0) {
        dispatch(UIactions.addErrorMessageToNamespace(form));
      }
      return false;
    }

    dispatch(UIactions.stopAction("GET_COUNTRIES", {}));
    const countriesList = response.data;
    const portugalIndex = countriesList.findIndex(
      (country) => country.data === "PT"
    );
    if (portugalIndex !== -1) {
      const portugalData = countriesList.splice(portugalIndex, 1)[0];
      countriesList.unshift(portugalData);
    }
    setCountries(countriesList);
  };

  useEffect(() => {
    getCountriesList();

    if (policyHolderProperties.length === 0) return;
    setName(getDataByNamespace("name", policyHolderProperties));
    setGender(getDataByNamespace("gender", policyHolderProperties));
    setCC(getDataByNamespace("cc", policyHolderProperties));
    setCCcountry("PT");
    setNationality("PT");
    setMobile(getDataByNamespace("mobile", policyHolderProperties));
    setEmail(getDataByNamespace("email", policyHolderProperties));
    setAddress(getDataByNamespace("address", policyHolderProperties));
    setPostalcode(getDataByNamespace("postal_code", policyHolderProperties));
    setCity(getDataByNamespace("city", policyHolderProperties));
    setSignaturetype(getDataByNamespace("signature_type", quoteProperties));
    setReceiveEmailMarketing(
      Boolean(
        getDataByNamespace("receive_email_marketing", policyHolderProperties)
      )
    );

    if (
      !isNullOrUndefined(
        getDataByNamespace("cc_country", policyHolderProperties)
      )
    ) {
      setCCcountry(getDataByNamespace("cc_country", policyHolderProperties));
    }

    if (
      !isNullOrUndefined(
        getDataByNamespace("nationality", policyHolderProperties)
      )
    ) {
      setNationality(getDataByNamespace("nationality", policyHolderProperties));
    }

    setGlobalDisclaimer(
      insuranceProduct.legal_disclaimers.find((x) => x.type === "global")
    );
    setProductDisclaimer(
      insuranceProduct.legal_disclaimers.find((x) => x.type === "by_product")
    );
    setTimeout(function () {}, 1500);
  }, [pdf]);

  const isSubmitButtonDisabled = () => {
    const checkForEmptyFields = () => {
      const isFieldEmpty = (field) =>
        field === null ||
        field === undefined ||
        field === "" ||
        field === "false" ||
        field === false;
      const mandatoryFields = [
        name,
        gender,
        cc,
        cc_country,
        nationality,
        mobile,
        email,
        address,
        postal_code,
        city,
      ];
      if (signature_type === "physical")
        mandatoryFields.push(
          physically_signed === true && docProcessStatus === true
        );
      mandatoryFields.push(
        flowNeedsSignature ? signature_type : data_sharing_confirmation
      );
      return mandatoryFields.some(isFieldEmpty);
    };

    return checkForEmptyFields();
  };

  return (
    <>
      <ToastContainer theme="dark" autoClose={10000} />
      {isLoader === true && (
        <ThreeDots
          height="90"
          width="90"
          radius="9"
          color="#FDBE14"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass="ajax-loader"
          visible={true}
        />
      )}

      <Row className="text-start m-0 simulation-div align-items-start">
        <Col md={9} className="leftbar">
          {window.innerWidth < 768 ? (
            <>
              <h3
                className="left-content-heading text-center d-block mt-2 mb-4"
                style={{ color: SECONDARY_COLOR }}
              >
                Dados do Beneficiário
              </h3>
              <Nav
                as="ul"
                className="mobile-bar step-three align-items-center justify-content-between"
              >
                <Nav.Item as="li" className="active"></Nav.Item>
                <Nav.Item as="li" className="active"></Nav.Item>
                <Nav.Item as="li" className="active"></Nav.Item>
                <Nav.Item as="li"></Nav.Item>
              </Nav>
            </>
          ) : (
            ""
          )}
          {window.innerWidth >= 768 ? (
            <>
              <div className="left-content-box active">
                <h3
                  className="left-content-heading m-0"
                  style={{ color: SECONDARY_COLOR }}
                >
                  <i className="bi bi-check-circle-fill"></i> Simulação
                </h3>
                <a
                  type="button"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    setFormDataUI();
                    dispatch(UIactions.setStep(0));
                  }}
                  className="edit_btn"
                  style={{ color: MAIN_COLOR }}
                >
                  <i className="bi bi-pencil"></i>Editar
                </a>
              </div>
              <div className="left-content-box active">
                <h3
                  className="left-content-heading m-0"
                  style={{ color: SECONDARY_COLOR }}
                >
                  <i className="bi bi-check-circle-fill"></i> Dados de{" "}
                  {userType === "lead" ? "Validação" : "Gerais"}
                </h3>
                <a
                  type="button"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    setFormDataUI();
                    dispatch(UIactions.previousStep());
                  }}
                  className="edit_btn"
                  style={{ color: MAIN_COLOR }}
                >
                  <i className="bi bi-pencil"></i>Editar
                </a>
              </div>
            </>
          ) : (
            ""
          )}
          <div className="left-content-box">
            {window.innerWidth >= 768 ? (
              <h3
                className="left-content-heading"
                style={{ color: SECONDARY_COLOR }}
              >
                <span
                  className="counter-box d-inline-flex align-items-center justify-content-center"
                  style={{ borderColor: SECONDARY_COLOR }}
                >
                  3
                </span>{" "}
                Dados do Beneficiário
              </h3>
            ) : (
              ""
            )}
            <Form
              method="post"
              className="beneficiary-form"
              onSubmit={handleSubmit}
            >
              <div className="form-heading" style={{ color: SECONDARY_COLOR }}>
                Dados Pessoais
              </div>
              <Row>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormControl
                    specs={getSpecsByNamespace("name", policyHolderProperties)}
                    type="text"
                    placeholder="Inserir..."
                    value={name}
                    onChange={(e) => {
                      setSmssent(false);
                      setName(e.target.value);
                    }}
                  />
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                  <FormRadio
                    specs={getSpecsByNamespace(
                      "gender",
                      policyHolderProperties
                    )}
                    value={gender}
                    onChange={(e) => {
                      setSmssent(false);
                      setGender(e.target.value);
                    }}
                  />
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                  <FormControl
                    specs={getSpecsByNamespace("cc", policyHolderProperties)}
                    type="text"
                    placeholder="Inserir..."
                    value={cc}
                    onChange={(e) => {
                      setSmssent(false);
                      setCC(e.target.value);
                    }}
                  />
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                  <FormSelect
                    specs={{
                      ...getSpecsByNamespace(
                        "cc_country",
                        policyHolderProperties
                      ),
                      options: countries,
                    }}
                    value={cc_country}
                    onChange={(e) => {
                      setSmssent(false);
                      setCCcountry(e.target.value);
                    }}
                  />
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                  <FormSelect
                    specs={{
                      ...getSpecsByNamespace(
                        "nationality",
                        policyHolderProperties
                      ),
                      options: countries,
                    }}
                    value={nationality}
                    onChange={(e) => {
                      setSmssent(false);
                      setNationality(e.target.value);
                    }}
                  />
                </Col>
                <div
                  className="form-heading"
                  style={{ color: SECONDARY_COLOR }}
                >
                  Contactos
                </div>
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                  <PhoneInput
                    specs={getSpecsByNamespace(
                      "mobile",
                      policyHolderProperties
                    )}
                    value={mobile}
                    onChange={setNewMobile}
                  />
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                  <FormControl
                    specs={getSpecsByNamespace("email", policyHolderProperties)}
                    placeholder="Inserir..."
                    type="email"
                    value={email}
                    onChange={(e) => {
                      setSmssent(false);
                      setEmail(e.target.value);
                    }}
                  />
                </Col>
                <div
                  className="form-heading"
                  style={{ color: SECONDARY_COLOR }}
                >
                  Morada
                </div>
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                  <FormControl
                    specs={getSpecsByNamespace(
                      "address",
                      policyHolderProperties
                    )}
                    placeholder="Inserir..."
                    type="address"
                    value={address}
                    onChange={(e) => {
                      setSmssent(false);
                      setAddress(e.target.value);
                    }}
                  />
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                  <InputMask
                    specs={getSpecsByNamespace(
                      "postal_code",
                      policyHolderProperties
                    )}
                    value={postal_code}
                    onChange={(e) => {
                      setSmssent(false);
                      setPostalcode(e.target.value);
                    }}
                    mask="9999-999"
                    placeholder="0000-000"
                  />
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                  <FormControl
                    specs={getSpecsByNamespace("city", policyHolderProperties)}
                    placeholder="Inserir..."
                    type="city"
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                      setSmssent(false);
                    }}
                  />
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormCheck
                    specs={getSpecsForCheckBox(
                      "receive_email_marketing",
                      policyHolderProperties,
                      userType
                    )}
                    value={receive_email_marketing}
                    onChange={(e) => {
                      setSmssent(false);
                      setReceiveEmailMarketing(!receive_email_marketing);
                    }}
                  />
                </Col>
                {!flowNeedsSignature && (
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <FormCheck
                      specs={{
                        namespace: "data_sharing_confirmation",
                        label: (
                          <p>
                            {`${
                              ["user", "guest"].includes(userType)
                                ? "Confirmo"
                                : "Confirmar"
                            } cedência dos dados pessoais à Habit${
                              data_sharing_document?.name ? ", cuja " : ""
                            }`}
                            {(data_sharing_document?.uri ?? false) && (
                              <a
                                href={data_sharing_document?.uri}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {data_sharing_document?.name ?? " Documentação"}
                              </a>
                            )}
                          </p>
                        ),
                      }}
                      value={data_sharing_confirmation}
                      onChange={(e) => {
                        setDataSharingConfirmation(!data_sharing_confirmation);
                      }}
                    />
                  </Col>
                )}
                {flowNeedsSignature ? (
                  <>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Form.Group className="mb-4">
                        <Form.Label>ACEITAÇÃO DE CLIENTE</Form.Label>
                        <label className="d-block form-check-label">
                          Será necessário validar a aceitação das condições
                          gerais e particulares do seguro. para isso basta
                          clicar botão ( enviar SMS ), de seguida irá receber um
                          SMS para o telefone preenchido no formulário ou
                          assinar no documento impresso
                        </label>
                      </Form.Group>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                      <FormRadio
                        specs={getSpecsByNamespace(
                          "signature_type",
                          quoteProperties
                        )}
                        value={signature_type}
                        onChange={(e) => {
                          setSignaturetype(e.target.value);
                          setSmssent(false);
                        }}
                        noLabel={true}
                      />
                    </Col>
                  </>
                ) : (
                  <></>
                )}
                {sms_sent === true && signature_type === "digital" ? (
                  <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Form.Group>
                      <Form.Label className="beneficiary-form-label form-label">
                        Código SMS
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="sms"
                        value={sms}
                        onChange={(e) => setSMS(e.target.value)}
                        placeholder="Inserir..."
                      />
                      <Link
                        className="sms_btn"
                        to="/"
                        onClick={(e) => {
                          e.preventDefault();
                          getSMSAsync();
                        }}
                      >
                        Enviar SMS de novo
                      </Link>
                    </Form.Group>
                  </Col>
                ) : (
                  ""
                )}

                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                  {window.innerWidth >= 768 ? (
                    <Form.Group>
                      {signature_type === "physical" && (
                        <>
                          <Button
                            className="mt-0 mb-3 simulation-button doc-btn"
                            variant="warning"
                            type="button"
                            style={{
                              background: SECONDARY_COLOR,
                              borderColor: SECONDARY_COLOR,
                            }}
                            onClick={docProcess}
                          >
                            Imprimir Documento
                          </Button>
                          <FormCheck
                            specs={getSpecsForCheckBox(
                              "physically_signed",
                              quoteProperties,
                              userType
                            )}
                            value={physically_signed}
                            onChange={(e) => {
                              setPhysicallysigned(!physically_signed);
                            }}
                          />
                        </>
                      )}
                      <SubmitButton isDisabled={isSubmitButtonDisabled()} />
                    </Form.Group>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Form>
          </div>
          <div className="left-content-box inactive">
            <h3 className="left-content-heading m-0" style={style}>
              <span className="counter-box d-inline-flex align-items-center justify-content-center">
                4
              </span>{" "}
              Pagamento
            </h3>
          </div>
          <div className="d-block footer-div">
            <div className="footer-text">
              {globalDisclaimer ? (
                <p className="m-0">{globalDisclaimer.text}</p>
              ) : (
                ""
              )}
              {productDisclaimer ? (
                <p className="m-0">{productDisclaimer.text}</p>
              ) : (
                ""
              )}
            </div>
          </div>
        </Col>
        <Col md={3} className="right-sidebar">
          <RightSidebar
            props={sidebarProps}
            previousFunction={() => dispatch(UIactions.previousStep())}
            submitFunction={handleSubmit}
            canSubmit={!isSubmitButtonDisabled()}
          />
        </Col>
      </Row>
    </>
  );
};

export default BeneficiaryData;
